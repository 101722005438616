import React, { useState } from 'react';
import "../../Component/NewScrollElement/NewScrollElement.css";
import "../../Component/ChatbotCardSection/ChatbotCardSection.css";
import { Dialog, Grid, Typography, useMediaQuery } from "@mui/material";

import "./Chatbot.css";
import '../../Pages/Paraphraser/Paraphraser.css'


import LandingFooter from '../../Component/Footer/LandingFooter';
import Navbar from '../Navbar/Navbar';
import ChatbotHero from '../../Component/ChatbotHero/ChatbotHero';
import ChatbotExplore from "../../Component/ChatbotExplore/ChatbotExplore";
import ChatbotExploreMobile from "../../Component/ChatbotExplore/ChatbotExploreMobile"
import ChatbotGradient from '../../Component/ChatbotGradient/ChatbotGradient';
import ChatbotFeatures from '../../Component/ChatbotFeatures/ChatbotFeatures';
import ChatbotStats from '../../Component/ChatbotStats/ChatbotStats';
import ChatbotWorkFlow from '../../Component/ChatbotWorkFlow/ChatbotWorkFlow';
import ChatbotExperience from '../../Component/ChatbotExperience/ChatbotExperience';
import ChatbotCardItems from '../../Component/ChatbotCardItems/ChatbotCardItems';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';
import ChatbotNewFrontSection from '../../Component/ChatbotNewFrontSection/ChatbotNewFrontSection';
import ChatbotWhatsappExpertiseSection from '../../Component/ChatbotWhatsappExpertiseSection/ChatbotWhatsappExpertiseSection';
import ChatbotCustomerServiceSection from '../../Component/ChatbotCustomerServiceSection/ChatbotCustomerServiceSection';
import LiveChatAISection from '../../Component/LiveChatAISection/LiveChatAISection';
import AIChatbotSimplified from '../../Component/AIChatbotSimplified/AIChatbotSimplified';
import CommonStructurePage from '../../Component/CommonStructurePage/CommonStructurePage';

import commonSection1 from './../../assests/LandingPage/commonSection1.svg';
import commonSection2 from './../../assests/LandingPage/commonSection2.svg';
import commonSection3 from './../../assests/LandingPage/commonSection3.svg';
import commonSection4 from './../../assests/LandingPage/commonSection4.svg';

import ChatbotCardSection from '../../Component/ChatbotCardSection/ChatbotCardSection';
import AiWhatsappScrollingSection from '../../Component/AiWhatsappScrollingSection/AiWhatsappScrollingSection';
import HeroSectionNew from '../../Component/HeroSectionNew/HeroSectionNew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import metaProvider from './../../assests/LandingPage/metaProvider.svg';
import rightmark from '../../assests/LandingPage/landing-right-mark.svg'
import LandingChatbotFrameSections from '../../Component/LandingChatbotFrameSections/LandingChatbotFrameSections';
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
import OminiChannelSection from '../../Component/OminiChannelSection/OminiChannelSection';
import AccordianImageSelection from '../../Component/GlobalComponents/AccordianImageSelection/AccordianImageSelection';

import acc1 from '../../assests/LandingPage/accordianImage1.png'
import acc2 from '../../assests/LandingPage/accordianImage2.png'
import acc3 from '../../assests/LandingPage/accordianImage3.png'
import acc4 from '../../assests/LandingPage/accordianImage4.png'
import { useNavigate } from 'react-router-dom';


const Chatbot = (props) => {
  const { flagBanner, setFlagBanner, blogHeader } = props

  // const [isBelow1200, setIsBelow1000] = useState(window.innerWidth < 1200);
  const isBelow1200 = useMediaQuery('(min-width: 0px) and (max-width: 1200px)')
  const [pageName, setPageName] = useState('Chatbot');
  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

  const navigate = useNavigate()

  const [chatbotFaqs, setChatbotFaqs] = useState([
    {
      question: "What makes this AI agent different from other chatbots?",
      answer: "Our AI agent isn’t just about conversations—it drives engagement with image carousels, quick replies, and actionable CTAs. It personalizes interactions, offers seamless human handoffs when needed, and integrates effortlessly with your existing tools, ensuring a smooth, efficient customer experience.",
    },
    {
      question: "How fast can I set it up on my website?",
      answer: "In just a few minutes! With our no-code, no workflow platform, you simply write what you need, and your chatbot is ready to go live. No complicated workflows or technical expertise required."
    },
    {
      question: "Can I customize the chatbot’s design and behavior?",
      answer: "Absolutely! From tone and personality to the chatbot’s visual design, you have complete control to match your brand perfectly. Embed it anywhere on your site—on product pages, forms, or checkout—so your customers get help where they need it most.",
    },
    {
      question: "Does the AI agent work with my existing systems?",
      answer: "Yes! Our AI agent integrates seamlessly with CRMs, helpdesk software, and other tools, ensuring your data flows smoothly and your team stays in sync."
    },
    {
      question: "What if the AI agent can’t answer a customer’s query?",
      answer:
        "No problem! When the AI reaches its limits, it hands over the conversation to a human agent smoothly—ensuring a seamless experience with zero disruption to the customer."
    },
    {
      question: "How does the chatbot help with engagement and conversions?",
      answer:
        "The AI agent interacts visually and dynamically—using image carousels, quick replies, and CTAs—to guide customers through product discovery, answer questions instantly, and drive meaningful actions like purchases or bookings."
    },
  ])

  const heroContentData = {
    contentID: 1,
    contentHeading1: "Instantly build Gen-AI Agent",
    contentHeading2: "Engage, Resolve and Deliver Across All Channels",
    // contentSubHeading: "Create powerful AI agents that seamlessly integrate with your website, WhatsApp, Facebook, Instagram and more. Instantly handle customer inquiries, process orders, manage returns, and automate key business actions, freeing your team to drive growth and innovation.",
    // contentSubHeading: [
    //   "Get Instant Solutions Powered by Real-Time Data (RAG) and AI Actions",
    //   "Drive Engagement with Dynamic, AI-Enhanced Interactive Messaging",
    //   "No-Code AI and Human Collaboration for Seamless Support"
    // ],
    buttonData: [
      {
        buttonStyle: "dark",
        buttonText: "Get Started",
        buttonlink: process.env.REACT_APP_REDIRECT_URL,
      },
      {
        buttonStyle: "light",
        buttonText: "Book a Demo",
        buttonlink: () => setFlagOpenBookaMeetingDemo(true)
      }
    ],
    listIcon: <img src={rightmark} style={{ width: '20px', height: 'auto', }} alt="" srcset="" />,
    flagListData: true,
    listData: [
      "Deliver Instant Solutions Powered by Real-Time Data (RAG) and AI Actions",
      "Boost Engagement with Dynamic, AI-Enhanced Interactive Messaging",
      "No-Code AI and Human Collaboration for Seamless Support"
    ],
    flagFloatImage: true,
    extraSection: [
      <Grid item className='front-head-btn-item'>
        <Grid container sx={{ justifyContent: 'start', gap: "30px" }} direction={{ xs: "column", sm: "row" }} flexWrap={"nowrap"} alignItems={"center"}>
          <Grid item>
            <img src={metaProvider} className='meta-provider-image-style' />
          </Grid>
        </Grid>
      </Grid>],
    contentImageSection: [
      {
        imgID: 1,
        imgName: "Drive Sales",
        imgLink: commonSection1,
      },
      {
        imgID: 2,
        imgName: "Engage Prospects",
        imgLink: commonSection2,
      },
      {
        imgID: 3,
        imgName: "Get More Leads",
        imgLink: commonSection3,
      },
      {
        imgID: 3,
        imgName: "Get More Leads",
        imgLink: commonSection3,
      },
    ]
  }


  const commonFormatData = [
    {
      flagwhite: false,
      formatId: 1,
      format_sub_field: "Chronox AI Chatbot",
      format_field_color: "rgba(106, 9, 125, 1)",
      format_heading: "The AI support agent for your growing business",
      format_details: ["No more frustrating workflows-just quick, accurate responses your customers will love.", "Chronox AI Chatbot handles queries instantly, understanding context to deliver precise answers without the usual back-and-forth. Elevate your customer experience with a chatbot that feels more human and less robotic."],
      format_button_text: "Discover AI Chatbots",
      onclick: () => { navigate('/ai-chatbot') },
      btn_data: {
        btn_percentage: "76%",
        btn_data: "visitors do not transfer request to human agent"
      },
      image_data: commonSection1
    },
    {
      flagwhite: false,
      formatId: 2,
      format_sub_field: "RAG & AI Actions",
      format_field_color: "rgba(10, 106, 221, 1)",
      format_heading: "Win More Loyal Customers with Advanced AI Automation & Real - Time Responses",
      format_details: ["Let your AI Chatbot take the lead—whether it's processing orders, handling returns, or answering complex customer queries with the power of Retrieval-Augmented Generation (RAG).", "Our AI-powered automation not only integrates seamlessly with your systems but also pulls from real-time data to ensure every response is accurate, relevant, and personalized."],
      format_button_text: "Boost Your Efficiency Today",
      onclick: () => { navigate('/') },
      btn_data: {
        btn_percentage: "60%",
        btn_data: "businesses report higher efficiency with AI-driven automation"
      },
      image_data: commonSection2
    },
    {
      flagwhite: true,
      formatId: 3,
      format_sub_field: "Live Agent Support",
      format_field_color: "rgba(253, 182, 100, 1)",
      format_heading: "Human Touch, When It Matters Most",
      format_details: ["AI handles the routine, while live agents manage the complex—giving your customers the best of both worlds. Chronox AI allows customers to switch to a live agent when they need a personal touch, ensuring that every interaction is handled with care. Keep your customers satisfied while optimizing your support team's efforts."],
      format_button_text: "Discover Live Chat",
      onclick: () => { navigate('/whatsapp-ai-chatbot') },
      btn_data: {
        btn_percentage: "58%",
        btn_data: "customers feel more confident when they know they can escalate to a human agent"
      },
      image_data: commonSection3
    },
    {
      flagwhite: true,
      formatId: 4,
      format_sub_field: "Integrations",
      format_field_color: "rgba(31, 56, 76, 1)",
      format_heading: "Seamlessly Connect with the Tools You Already Use",
      format_details: ["Maximize your AI chatbot’s potential by integrating it effortlessly with the platforms your business relies on. From CRMs and helpdesk software to payment gateways and marketing platforms, our AI solution fits right into your existing workflow.", "No more switching between systems or manual data entry—everything works in sync to provide a streamlined experience for both your team and your customers."],
      format_button_text: "Connect Your Systems Seamlessly",
      onclick: () => { navigate('/facebook-ai-chatbot') },
      btn_data: {
        btn_percentage: "75%",
        btn_data: "Businesses reduce operational bottlenecks by integrating with their systems"
      },
      image_data: commonSection4
    },
  ]

  const ChatbotCustomerServiceSectionData = {
    heading: "Create customer service that sells",
    subHeading: "It's like having your best salesperson on every call—helping customers, closing deals, and making every interaction count.",
    serviceData: [
      {
        serviceImage: "greet",
        serviceId: 1,
        headingDate: "Greet and Engage",
        subHeadingData: "Start every conversation like you’re welcoming an old friend-kick off with a warm, personal greeting that makes every customer feel instantly valued and recognized.",
        serviceImage: acc1,
      },
      {
        serviceImage: "understand",
        serviceId: 2,
        headingDate: "Understand and Qualify",
        subHeadingData: "It’s like having a casual coffee chat where you effortlessly get to know what your customers need-no pressure, just the right questions to see if they’re a perfect fit.",
        serviceImage: acc2,
      },
      {
        serviceImage: "present",
        serviceId: 3,
        headingDate: "Present and Nurture",
        subHeadingData: "It’s like knowing exactly when to share the perfect story -  introducing your product at just the right moment, so it feels like the solution they’ve been searching for all along.",
        serviceImage: acc3,
      },
      {
        serviceImage: "overcome",
        serviceId: 4,
        headingDate: "Overcome Objectives",
        subHeadingData: "Handle concerns like a pro-instantly offer the right answers that make customers feel understood and confident, turning hesitation into trust without sounding like a salesperson.",
        serviceImage: acc4,
      },
    ],
    flagImageDirection: "left",
  }

  return (

    <Grid container sx={{ position: 'relative' }}>
      {
        flagOpenBookaMeetingDemo &&
        <Dialog open={flagOpenBookaMeetingDemo}
          maxWidth='lg'
          sx={{ '&.MuiPaper-root': { width: '100%' } }}
          className="iframe-dialog"
        >
          <BookAMeetingDialog
            flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
            setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
          />
        </Dialog>
      }
      <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />
      <Grid item xs={12} mt={{ xs: flagBanner ? 10 : 0, md: flagBanner ? 8 : 0 }} sx={{ overflowX: "hidden" }}>
        <Grid container>

          <Grid item xs={12} className='brsw-new-head-section-main-item'>
            {/* <ChatbotNewFrontSection /> */}
            <HeroSectionNew content={heroContentData} flagBanner={flagBanner} />
          </Grid>

          <Grid item xs={12} sx={{ margin: "20px 0px" }}>
            <LandingChatbotFrameSections />
          </Grid>

          <Grid item xl={9} lg={9} md={10.5} sm={11} xs={11} className='brsw-new-expertise-section-main-item'>
            <AiWhatsappScrollingSection />
          </Grid>

          {/* <Grid item xs={12} className='brsw-new-customer-section-main-item'>
            <ChatbotCustomerServiceSection />
          </Grid> */}

          <Grid item xs={12} className='brsw-new-customer-section-main-item'>
            <AccordianImageSelection content={ChatbotCustomerServiceSectionData} />
          </Grid>

          {/* <Grid item xs={12} className='brsw-new-expertise-section-main-item'>
            <ChatbotWhatsappExpertiseSection />
          </Grid> */}

          {commonFormatData.map((format) =>
            <Grid item xs={12} className='brsw-new-common-section-main-item'>
              <Grid container>
                <CommonStructurePage format={format} />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} className='brsw-new-liveai-section-main-item'>
            <LiveChatAISection />
          </Grid>

          <Grid item xs={12} className='cb-omini-section-item'>
            <OminiChannelSection />
          </Grid>

          <Grid item xs={12} className='chatbot-card-section chatbot-card-bg'>
            <ChatbotCardSection blogHeader={blogHeader} />
          </Grid>

          <Grid item xs={12} className='chatbot-faq-section'>
            <ChatbotFAQs faqData={chatbotFaqs} />
          </Grid>

          <Grid item xs={12} className='brsw-new-aisimplified-section-main-item'>
            <AIChatbotSimplified />
          </Grid>

          <Grid item xs={12} className='pr-overlap-item'>
            <ParaphraseOverlapFooter pageName={pageName} />
          </Grid>

          <Grid item xs={12} className='brsw-footer-component-main-item'>
            <LandingFooter />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default Chatbot