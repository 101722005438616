import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import SideBySideSectionWithBtn from '../../Component/GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import { Grid, useMediaQuery } from '@mui/material'
import './SlackAiChatbot.css'
import ClientLogoSection from '../../Component/GlobalComponents/ClientLogoSection/ClientLogoSection'
import ChatbotWhatsappGridSection from '../../Component/ChatbotWhatsappGridSection/ChatbotWhatsappGridSection'
import firstImage from '../../assests/ChatbotWhatsapp/cw-chat-first-card-1.svg'
import secondImage from '../../assests/ChatbotWhatsapp/cw-chat-second-card-1.svg'
import thirdImage from '../../assests/ChatbotWhatsapp/cw-chat-third-card-1.svg'
import messanger from '../../assests/messenger.svg'
import AiWhatsappScrollingSection from '../../Component/AiWhatsappScrollingSection/AiWhatsappScrollingSection'
import GlobalCTABanner from '../../Component/GlobalComponents/GlobalCTABanner/GlobalCTABanner'
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs'
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter'
import LandingFooter from '../../Component/Footer/LandingFooter'
import EastIcon from '@mui/icons-material/East';
import img1 from '../../assests/slackHeroImage.png'


const SlackAiChatbot = (props) => {
    const { flagBanner, setFlagBanner } = props
    const isScreenWidthBelow900 = useMediaQuery('(max-width : 900px) and (min-width : 0px)')

    const heroSectionData = {
        heading: "ChatBot for Slack",
        description: "Create custom chatbots for Slack and streamline office workflow.",
        image: img1,
        btnText: "Get Started",
        btnGlobalCustomStyle: 'slack-hero-section-btn-style',
        btnVariant: 'contained',
        flagImageDirection: "right",
        textDirection: "center",
        headingGlobalCustomStyle: "slack-hero-section-heading-style",
        descriptionGlobalCustomStyle: "slack-hero-section-description-style",
        flagLogoImageSrc: "https://cdn-icons-png.flaticon.com/512/2111/2111615.png",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }
    // const ClientLogos = [
    //     {
    //         image: "https://www.citypng.com/public/uploads/preview/hd-glovo-logo-transparent-background-701751694710478xc9jciftjj.png",
    //         alt: "image1"
    //     },
    //     {
    //         image: "https://www.citypng.com/public/uploads/preview/hd-glovo-logo-transparent-background-701751694710478xc9jciftjj.png",
    //         alt: "image2"
    //     },
    //     {
    //         image: "https://www.citypng.com/public/uploads/preview/hd-glovo-logo-transparent-background-701751694710478xc9jciftjj.png",
    //         alt: "image3"
    //     },
    //     {
    //         image: "https://www.citypng.com/public/uploads/preview/hd-glovo-logo-transparent-background-701751694710478xc9jciftjj.png",
    //         alt: "image4"
    //     },
    // ]
    const groupSectionData = [
        {
            mainHeading: "Instantly sync your Data Flow across your systems",
            subheading1: "Connect in real-time with APIs, CRM, and databases to ensure data is always current and accurate.",
            subheading2: "Enable smarter decisions with data-driven insights directly within your WhatsApp conversations.",
            subheading3: "Reduce response times and increase efficiency with automated data retrieval and updates.",
            leftSideimage: false,
            image: firstImage,
            altText: 'Connect with all platform and data-driven insights',
            points: [
                { label: 'Real-time connectivity', img: messanger },
                { label: 'Data-driven insights', img: messanger },
                { label: 'Smart WhatsApp decision', img: messanger },
            ]
        },
        {
            mainHeading: "Easy toggle between AI and Human Interaction",
            subheading1: "Toggle between AI-driven and manual conversations seamlessly to best suit the customer’s needs.",
            subheading2: "Use AI-assisted prompts to enhance human interactions, ensuring no detail is missed.",
            subheading3: "Maintain control with options to intervene manually when nuanced conversations are required.",
            leftSideimage: isScreenWidthBelow900 ? false : true,
            image: secondImage,
            altText: 'Easy interaction with AI and human entities',
            points: [
                { label: 'Seamless Toggles', img: messanger },
                { label: 'AI-assisted prompts', img: messanger },
                { label: 'Ensure detail accuracy', img: messanger },
            ]
        },
        {
            mainHeading: "Automate your Tasks & Messaging using API",
            subheading1: "Deploy automatic WhatsApp messages through our robust API, ensuring timely and consistent communication with users.",
            subheading2: "Customize message templates to reflect your brand’s tone and style, making automated interactions feel as personal as live chats.",
            subheading3: "Utilize automation to handle common inquiries and free up your team to focus on more complex customer needs, enhancing overall service efficiency.",
            leftSideimage: false,
            image: thirdImage,
            altText: 'Auto-generated message templates for your Tasks & Messaging',
            points: [
                { label: 'Automatic WhatsApp messages', img: messanger },
                { label: 'Brand-specific templates', img: messanger },
                { label: 'Personalized message templates', img: messanger },
            ]
        },

    ]
    const CTABannerData = {
        heading: "Lorem Ipsum has been the industry's standard dummy text",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        btnText: "Lorem Ipsum"
    }
    const faqData = [
        {
            question: "How does a WhatsApp chatbot work?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "What are the benefits of using WhatsApp chatbots?",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "Are WhatsApp chatbots secure?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can I ask the chatbot?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
            question: "How does the chatbot understand my questions?",
            answer:
                "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ];

    const [pageName, setPageName] = useState('Chatbot');


    return (
        <>
            <Grid container>
                <Navbar setFlagBanner={setFlagBanner} flagBanner={flagBanner} />
                <Grid item xs={12} mt={flagBanner ? 7 : 0}>
                    <Grid container>
                        <Grid item xs={12} className='slack-side-by-side-hero-section'>
                            <SideBySideSectionWithBtn content={heroSectionData} flagHeroSection />
                        </Grid>
                        <Grid item xs={9} className='slack-client-logo-section' >
                            {/* <Grid container alignItems={"center"} gap={3} direction={"column"}>
                                <Grid item sx={{ fontSize: "36px", fontWeight: "600" }}>Trusted by 30+ businesses</Grid>
                                <ClientLogoSection content={ClientLogos} />
                            </Grid> */}
                            <AiWhatsappScrollingSection />
                        </Grid>
                        <Grid item xs={12}>
                            <ChatbotWhatsappGridSection groupSectionData={groupSectionData} />
                        </Grid>
                        <Grid item xs={12} className='slack-image-banner'>
                            <GlobalCTABanner content={CTABannerData} flagImage image={'https://cdn.prod.website-files.com/622611a2b23d0e6b270fba81/63ed0c34b1d4bf07890932ca_all-informations-in-slack_hu7253b0cff3ae198a75e8e7a709bb0101_34460_1336x0_resize_lanczos_3.png'} />
                        </Grid>
                        <Grid item xs={12}>
                            <ChatbotFAQs faqData={faqData} />
                        </Grid>
                        <Grid item xs={12} className='slack-pr-overlap-item'>
                            <ParaphraseOverlapFooter pageName={pageName} />
                        </Grid>

                        <Grid item xs={12} className='brsw-footer-component-main-item'>
                            <LandingFooter />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SlackAiChatbot
