import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import './BookACalendlyDialog.css'
import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


const BookACalendlyDialog = (props) => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container sx={{ justifyContent: 'end' }}>
                                <Grid item xs={1}
                                    sx={{
                                        position: 'absolute',
                                        background: 'black',
                                        padding: '5px',
                                        borderRadius: '50%',
                                        right: '20px',
                                        top: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CloseIcon
                                        sx={{ cursor: 'pointer', color: 'white' }}
                                        onClick={() => props.setFlagOpenBookaMeetingDemo(false)} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <iframe
                                src="https://calendly.com/d/cpn9-qmc-ybz/chronox-ai-meeting"
                                frameborder="0"
                                style={{
                                    height: '90vh',
                                    width: '100%'
                                }}
                            >
                            </iframe>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default BookACalendlyDialog