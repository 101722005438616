import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import EastIcon from '@mui/icons-material/East';
import './Instagram.css'
import omini from '../../assests/LandingPage/chatbot-ominichannel.svg'

import Navbar from '../Navbar/Navbar';
import InstagramHeadSection from '../../Component/InstagramHeadSection/InstagramHeadSection';
import AiWhatsappScrollingSection from '../../Component/AiWhatsappScrollingSection/AiWhatsappScrollingSection';
import InstaAutomateSection from '../../Component/InstaAutomateSection/InstaAutomateSection';
import SideBySideSectionWithBtn from '../../Component/GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn';
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';
import AIChatbotSimplified from '../../Component/AIChatbotSimplified/AIChatbotSimplified';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import LandingFooter from '../../Component/Footer/LandingFooter';
import InstagramImproveTeamCollabration from '../../Component/InstagramImproveTeamCollabration/InstagramImproveTeamCollabration';
import AIFacebookEngagement from '../../Component/AIFacebookEngagement/AIFacebookEngagement';


const Instagram = (props) => {

    const { setFlagBanner, flagBanner } = props
    const [pageName, setPageName] = useState('Chatbot');


    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "How does a WhatsApp chatbot work?",
            answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
            question: "What are the benefits of using WhatsApp chatbots?",
            answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
            question: "Are WhatsApp chatbots secure?",
            answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
            question: "What can I ask the chatbot?",
            answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
            question: "How does the chatbot understand my questions?",
            answer:
                "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ])


    const data = [
        {
            head: "81%",
            subHead: '81% of people use Instagram to research products and services',
            bgColor: '#001b47',
            color: '#ffffff'
        },
        {
            head: "200M",
            subHead: '200 million Instagram users visit at least one business profile daily',
            bgColor: '#fff',
            color: '#000000'
        },
        {
            head: "130M",
            subHead: '130 million people tap on shopping posts every month.',
            bgColor: '#ffc859',
            color: '#000000'
        },
        {
            head: "90%",
            subHead: '90% of Instagram users follow a business',
            bgColor: '#ffc859',
            color: '#000000'
        },
        {
            head: "50%",
            subHead: '50% of people have visited a website after seeing a product or service on Instagram',
            bgColor: '#fff',
            color: '#000000'
        },
        {
            head: "1.393 billion",
            subHead: 'nstagram has at least 1.393 billion users around the world',
            bgColor: '#001b47',
            color: '#ffffff'
        },
    ]

    return (
        <>
            <Navbar setFlagBanner={setFlagBanner} flagBanner={flagBanner} />
            <Grid container>
                <Grid item x={12}>
                    <InstagramHeadSection flagBanner={flagBanner} />
                </Grid>

                <Grid item xs={10} className='in-trusted-item'>
                    <AiWhatsappScrollingSection />
                </Grid>

                <Grid item xs={10} className='in-trusted-item'>
                    <InstaAutomateSection />
                </Grid>

                <Grid item xs={10} className='in-trusted-item'>
                    <InstagramImproveTeamCollabration />
                </Grid>

                <Grid item xs={12} className='in-trusted-item'>
                    <AIFacebookEngagement data={data} />
                </Grid>

                <Grid item xs={12} className='chatbot-faq-section'>
                    <ChatbotFAQs faqData={chatbotFaqs} />
                </Grid>

                <Grid item xs={12} className='in-new-aisimplified-section-main-item'>
                    <AIChatbotSimplified />
                </Grid>

                <Grid item xs={12} className='pr-overlap-item'>
                    <ParaphraseOverlapFooter pageName={pageName} />
                </Grid>

                <Grid item xs={12} className='brsw-footer-component-main-item'>
                    <LandingFooter />
                </Grid>
            </Grid>
        </>
    )
}

export default Instagram