import { Grid } from '@mui/material'
import React, { useState } from 'react'
import SideBySideSectionWithBtn from '../../Component/GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import EastIcon from '@mui/icons-material/East';
import './InteractiveMessage.css'
import Navbar from '../Navbar/Navbar';
import ChatbotFAQs from '../../Component/ChatbotFAQs/ChatbotFAQs';
import AIChatbotSimplified from '../../Component/AIChatbotSimplified/AIChatbotSimplified';
import ParaphraseOverlapFooter from '../../Component/ParaphraserSections/ParaphraseOverlapFooter';
import LandingFooter from '../../Component/Footer/LandingFooter';
import GlobalTab from '../../Component/GlobalTabChanges/GlobalTab';
import img1 from "../../assests/LandingPage/image 1.png"
import img2 from "../../assests/LandingPage/image 2.png"
import img3 from "../../assests/LandingPage/image 3.png"
import img4 from "../../assests/LandingPage/image 4.png"
import img5 from "../../assests/LandingPage/image 5.png"
import mainImg from "../../assests/Platform/Interactive/interactive-main-img.png"
import subImg from "../../assests/Platform/Interactive/interactive-chat.png"


const InteractiveMessage = (props) => {


    const { flagBanner, setFlagBanner } = props
    const [pageName, setPageName] = useState('Chatbot');

    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
            question: "What makes this AI agent different from other chatbots?",
            answer: "Our AI agent isn’t just about conversations—it drives engagement with image carousels, quick replies, and actionable CTAs. It personalizes interactions, offers seamless human handoffs when needed, and integrates effortlessly with your existing tools, ensuring a smooth, efficient customer experience.",
        },
        {
            question: "How fast can I set it up on my website?",
            answer: "In just a few minutes! With our no-code, no workflow platform, you simply write what you need, and your chatbot is ready to go live. No complicated workflows or technical expertise required."
        },
        {
            question: "Can I customize the chatbot’s design and behavior?",
            answer: "Absolutely! From tone and personality to the chatbot’s visual design, you have complete control to match your brand perfectly. Embed it anywhere on your site—on product pages, forms, or checkout—so your customers get help where they need it most.",
        },
        {
            question: "Does the AI agent work with my existing systems?",
            answer: "Yes! Our AI agent integrates seamlessly with CRMs, helpdesk software, and other tools, ensuring your data flows smoothly and your team stays in sync."
        },
        {
            question: "What if the AI agent can’t answer a customer’s query?",
            answer:
                "No problem! When the AI reaches its limits, it hands over the conversation to a human agent smoothly—ensuring a seamless experience with zero disruption to the customer."
        },
        {
            question: "How does the chatbot help with engagement and conversions?",
            answer:
                "The AI agent interacts visually and dynamically—using image carousels, quick replies, and CTAs—to guide customers through product discovery, answer questions instantly, and drive meaningful actions like purchases or bookings."
        },
    ])

    const heroSectionData = {
        heading: "Take Customer Engagement to the next level with Interactive AI Messaging!",
        description: "Communication needs to be more than just informative—it needs to be engaging! Our AI-powered interactive messages transform static chats into dynamic conversations that captivate, guide, and convert users.",
        image: mainImg,
        btnText: "Get Started for free",
        btnGlobalCustomStyle: 'inm-get-started-btn',
        btnVariant: 'contained',
        globalEndIcon: <EastIcon />,
        flagImageDirection: "right",
        creditlabel: true,
        btnGlobalCustomStyle: 'omini-gbl-contained-btn-style',
        btnVariant: 'contained',
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')
    }

    const heroSectionData2 = {
        headingLabel: "No Code Setup",
        image: subImg,
        headingLabelCustomStyle: "im-heading-label",
        heading: "Streamline customer engagement workflows on WhatsApp with a few clicks",
        description: "Chronox's no-code visual builder empowers you to create automated customer engagement workflows.Use predefined templates and media to reach prospects and customers with the right message based on customer actions and events.Save time and improve your productivity.",
        btnText: "",
        btnGlobalCustomStyle: '',
        btnVariant: '',
        globalEndIcon: <EastIcon />,
        flagImageDirection: "left",
        creditlabel: false,
        // onClick: () => setFlagOpenBookaMeetingDemo(true),
    }

    const tabData = {
        mainHead: "Turn your conversations into revenue",
        tabButton: [
            {
                img: img1,
                headText: "Image Card Carousel",
                subText: "Appointment Booking",
                caption: `The Image Card Carousel feature helps businesses visually present multiple services or products, such as doctor availability or events, in a scrollable format. Each card includes key details like provider names and availability along with action buttons such as "Book an Appointment" or "Show Availability." This intuitive setup simplifies decision-making by allowing users to explore options quickly and take immediate action.`
            },
            {
                img: img2,
                headText: "Information Card",
                subText: "Quote Generation",
                caption: `The Image Card Carousel feature helps businesses visually present multiple services or products, such as doctor availability or events, in a scrollable format. Each card includes key details like provider names and availability along with action buttons such as "Book an Appointment" or "Show Availability." This intuitive setup simplifies decision-making by allowing users to explore options quickly and take immediate action.`
            },
            {
                img: img3,
                headText: "Suggestion Chips",
                subText: "Predefined categories",
                caption: `The Suggestion Chips feature saves users time by offering pre-set reply options, enabling faster and more intuitive interactions. These clickable suggestions streamline conversations by reducing the need for typing, making it easy for users to select relevant options with just a tap.In this example, the virtual assistant presents predefined categories such as cocktails, beer, whiskey, wine, and non-alcoholic drinks. This feature ensures smooth, guided interactions, improving user experience by delivering quick access to relevant information or actions.`
            },
            {
                img: img4,
                headText: "Call-to-Action Buttons",
                subText: "Event Registration",
                caption: `The Call-to-Action (CTA) Buttons feature boosts engagement by providing users with easy-to-click buttons that smoothly guide them to the next step in their journey. A common use case is event registration, where participants can register directly through interactive platforms. Each card displays an event image along with key details, accompanied by a "Register Now" button to prompt quick and seamless responses.`
            },
            {
                img: img5,
                headText: "Quick Replies",
                subText: "Surveys & Feedback",
                caption: `The Quick Replies feature enhances user interactions by providing predefined response options, making it faster and easier for users to engage. A great example of this feature is seen in surveys and feedback campaigns. Businesses can use bots to run targeted surveys, automate feedback collection, and capture valuable insights from customers. These quick reply buttons allow users to provide instant responses with minimal effort, improving response rates.`
            },
        ]
    }


    return (
        <>
            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} className='im-main-head-item'>
                            <SideBySideSectionWithBtn content={heroSectionData} />
                        </Grid>

                        <Grid item xs={12} className='im-comman-item'>
                            <SideBySideSectionWithBtn content={heroSectionData2} />
                        </Grid>

                        <Grid item xs={12} className='im-main-head-item'>
                            <GlobalTab tabData={tabData} />
                        </Grid>

                        <Grid item xs={12} className='im-comman-item'>
                            <ChatbotFAQs faqData={chatbotFaqs} />
                        </Grid>

                        <Grid item xs={12} className='im-simplified-item'>
                            <AIChatbotSimplified />
                        </Grid>

                        <Grid item xs={12} className='im-overlap-item'>
                            <ParaphraseOverlapFooter pageName={pageName} />
                        </Grid>

                        <Grid item xs={12} className='brsw-footer-component-main-item'>
                            <LandingFooter />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default InteractiveMessage