import { Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import './InstaAutomateSection.css'
// import img1 from '../../assests/LandingPage/img-automation-1.webp'
// import img2 from '../../assests/LandingPage/img-automation-2.webp'
// import img3 from '../../assests/LandingPage/img-automation-3.webp'
import SideBySideSectionWithBtn from '../GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import EastIcon from '@mui/icons-material/East';

import img2 from '../../assests/Solution/Instagram/instagram 2.png'
import img3 from '../../assests/Solution/Instagram/instagram 3.png'
import img4 from '../../assests/Solution/Instagram/instagram 4.png'


const InstaAutomateSection = () => {

    const isBelow900 = useMediaQuery('(min-width: 0px) and (max-width: 900px)')


    const heroSectionData = {
        heading: "Send product recommendations",
        description: "Recommend products directly in the Instagram conversation with done-for-you sales Flows.",
        image: img2,
        btnText: "Integrate Instagram with Chronox",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />,
        flagImageDirection: "right",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')

    }
    const heroSectionData2 = {
        heading: "Collect your visitors’ details on autopilot",
        description: "Automatically collect your customers’ contact and order details, such as names, emails, phone numbers, and addresses.",
        image: img3,
        btnText: "Integrate Instagram with Chronox",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />,
        flagImageDirection: isBelow900 ? "right" : "left",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }
    const heroSectionData3 = {
        heading: "Offer discounts at the right time",
        description: "Send personalized discount messages that trigger when users meet certain conditions, such as being a return customer, ordering over a specific value threshold, and more.",
        image: img4,
        btnText: "Integrate Instagram with Chronox",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />,
        flagImageDirection: "right",
        onClick: () => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')
    }



    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xl={5} lg={8} md={9.5} sm={11} xs={12} className='automate-span'>
                            <span>Automate your Instagram sales with no-code conversion Flows</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='in-automate-item'>
                    <SideBySideSectionWithBtn content={heroSectionData} />
                </Grid>
                <Grid item xs={12} className='in-automate-item'>
                    <SideBySideSectionWithBtn content={heroSectionData2} />
                </Grid>
                <Grid item xs={12} className='in-automate-item'>
                    <SideBySideSectionWithBtn content={heroSectionData3} />
                </Grid>
            </Grid>
        </>
    )
}


export default InstaAutomateSection