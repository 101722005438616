import { Grid } from '@mui/material'
import React, { useState } from 'react'
import './FoodSectorComponents.css'
import Navbar from '../../../Pages/Navbar/Navbar'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommonFormatImageContents from '../../CommonFormatImageContent/CommonFormatImageContents';
import demoImage1 from './../../../assests/LandingPage/commonSection1.svg'
import demoImage2 from './../../../assests/LandingPage/commonSection2.svg'
import demoImage3 from './../../../assests/LandingPage/commonSection3.svg'
import HeroSectionNew from '../../HeroSectionNew/HeroSectionNew';
import heroImage from './../../../assests/Industries/HealthCare/health-care-hero.svg'
import FooterComponent from '../HealthCareComponents/FooterComponent';
import ChannelCardsComponent from '../HealthCareComponents/ChannelCardsComponent';
import LandingFooter from '../../Footer/LandingFooter';
import AiWhatsappScrollingSection from '../../AiWhatsappScrollingSection/AiWhatsappScrollingSection';
import ChatbotFAQs from '../../ChatbotFAQs/ChatbotFAQs';

const FoodSectorComponents = (props) => {
    const { flagBanner, setFlagBanner } = props;

    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
          question: "How does a WhatsApp chatbot work?",
          answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
          question: "What are the benefits of using WhatsApp chatbots?",
          answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
          question: "Are WhatsApp chatbots secure?",
          answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
          question: "What can I ask the chatbot?",
          answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
          question: "How does the chatbot understand my questions?",
          answer:
            "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ])

    const heroContentData = {
        contentID: 1,
        contentHeading1: "Streamline Food Services with AI:",
        contentHeading2: " Smart Solutions for Efficient Operations and Better Customer Experience",
        contentSubHeading: "From managing orders to personalized recommendations, our AI chatbot helps you optimize efficiency while delivering a seamless dining experience. Take the stress out of operations with intuitive, automated support tailored to your needs.",
        buttonData: [
            {
                buttonStyle: "dark",
                buttonText: "Get Started"
            },
            {
                buttonStyle: "light",
                buttonText: "Book a Demo"
            }
        ],
        listIcon: <CheckCircleOutlineIcon sx={{fontSize: "20px"}} />,
        flagListData: false,
        // listData: [
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi."
        // ],
        flagFloatImage: false,
        contentImageSection: [
            {
                imgID: 1,
                imgName: "Image 1",
                imgLink: heroImage,
            },
        ]
    }

    const foodSectorData = [
        {
            healthCareID: 1,
            heading: "24/7 Customer Support",
            subHeading: "Always Available for Your Customers",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Ensure your customers never face delays. With our 24/7 AI support, they receive prompt assistance at any hour.",
            subData: [
                "Quickly resolve customer inquiries.",
                "Automate responses for order processing and delivery queries.",
                "Seamlessly transition to human agents when required." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1
            // healthCareID: 1,
            // heading: "Enhance Customer Engagement & Boost Sales in the Food Industry",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            // subData: [
            //     "Make it easy for customers to order food, book tables, and inquire about dietary options, all through quick and responsive chat interactions.",
            //     "Provide tailored suggestions based on customer preferences, whether they are vegan, gluten-free, or have other dietary needs, ensuring a delightful dining experience.",
            //     "Gain insights from customer feedback to enhance your services and offerings, ensuring continuous improvement and customer satisfaction." 
            // ],
            // buttonData: "Explore Conversational Marketing for the Food Industry",
            // imgData: demoImage1
        },
        {
            healthCareID: 2,
            heading: "Effortless Ordering Experience",
            subHeading: "Simple and Quick Ordering Anytime",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Streamline the ordering process for your customers. Our AI system makes placing orders easy and efficient.",
            subData: [
                "AI handles order placements and adjustments swiftly.",
                "Provide real-time notifications to keep customers informed.",
                "Allow customers to choose their preferred method for placing orders (app, website, phone)." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1,

            // healthCareID: 2,
            // heading: "Effortlessly Manage Orders & Reservations with Automated Assistance",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px"}}/>,
            // subData: [
            //     "Offer your customers a seamless experience to place food orders or make reservations through a chatbot on your website or on WhatsApp.",
            //     "Empower your customers to easily modify their orders or reservation details, giving them flexibility and control over their dining experience.",
            //     "Increase satisfaction by sending order updates, reminders, and follow-up surveys automatically, keeping your customers informed and engaged." 
            // ],
            // buttonData: "Learn more about Proactive Messaging",
            // imgData: demoImage2
        },
        {
            healthCareID: 3,
            heading: "Engaging Customer Interactions",
            subHeading: "Connect with Customers Seamlessly",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Enhance customer loyalty with personalized outreach. Our AI manages follow-ups and promotions with ease.",
            subData: [
                "Send targeted messages about promotions and updates.",
                "Interact with customers through various platforms (email, SMS, chat).",
                "Convert casual visitors into devoted customers through real-time engagement." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1

            // healthCareID: 3,
            // heading: "Deliver On-demand Customer Support",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px"}}/>,
            // subData: [
            //     "Engage with food lovers directly via popular messaging platforms like Facebook, Google Business Messages (GBM), Instagram, and WhatsApp.",
            //     "Guide potential customers to discover your menu, special offers, and delivery options at the perfect moment in their decision-making process.",
            //     "Assist customers in finding your nearest restaurant locations, placing orders for pickup or delivery, and accessing services that cater to their dining needs." 
            // ],
            // buttonData: "Read more about Customer Care",
            // imgData: demoImage3
        },
        {
            healthCareID: 4,
            heading: "Expand Brand Reach",
            subHeading: "Automatically Connect with New Customers",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Grow your customer base effortlessly. Automate outreach to enhance brand visibility and attract new customers.",
            subData: [
                "Send customized messages for new promotions and offers.",
                "Engage with potential customers across different platforms (social media, email, SMS).",
                "Transform website traffic into orders through effective live chat support." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1
        }
    ] 

    return (
        <Grid container>
            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />

            <Grid item xs={12} mt={{ xs: flagBanner ? 10 : 0, md: flagBanner ? 8 : 0 }} sx={{ overflowX: "hidden" }}>
                <Grid container>
                    <Grid item xs={12} className='healthcare-new-hero-section'>
                        <HeroSectionNew content={heroContentData}/>
                    </Grid>

                    <Grid item xs={9} className='brsw-new-expertise-section-main-item'>
                        <AiWhatsappScrollingSection />
                    </Grid>

                    {
                        foodSectorData.map((data) => 
                            <Grid item xs={12} className='common-format-new-head-section-main-item'>
                                <CommonFormatImageContents content={data} />
                            </Grid>
                        )
                    }

                    <Grid item xs={12} className='common-format-new-card-section'>
                        <ChannelCardsComponent />
                    </Grid>

                    <Grid item xs={12} className='common-format-new-footer-section'>
                        <FooterComponent />
                    </Grid>

                    <Grid item xs={12} className='chatbot-faq-section'>
                        <ChatbotFAQs faqData={chatbotFaqs} />
                    </Grid>

                    <Grid item xs={12}>
                        <LandingFooter />
                    </Grid>
                    
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FoodSectorComponents
