import React from 'react'
import './GlobalCTABanner.css'
import { Button, Grid, Typography } from '@mui/material';

const GlobalCTABanner = (props) => {
    const { content, flagImage, image } = props;
    return (
        <>
            <Grid container>
                <Grid item xl={7} lg={8.5} md={11} sm={12} xs={12} sx={{ margin: '0 auto' }}>
                    <Grid container alignItems={"center"}>
                        {flagImage &&
                            <Grid item xs={6}>
                                <img style={{ maxWidth: "900px", width: "600px", marginBottom: "-90px" }} src={image} alt={image} />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <Grid container textAlign={`${flagImage ? "flex-start" : "center"}`} justifyContent={`${flagImage ? "start" : "center"}`}>
                                <Typography variant="h4" component="h1" className='gcta-head-text' textAlign={`${flagImage ? "flex-start" : "center"}`}>
                                    {content.heading}
                                </Typography>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='gcta-subhead-item'>
                                    <Typography variant='span' className='gcta-subhead-text'>
                                        {content.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className='gcta-btn-text-item'>
                                    <Grid container sx={{ justifyContent: flagImage ? 'flex-start' : 'center', gap: '20px' }}>
                                        <Grid item >
                                            <Button
                                                variant='outlined'
                                                fullWidth
                                                size='medium'
                                                className='gcta-btn'
                                            >
                                                {content.btnText}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default GlobalCTABanner
