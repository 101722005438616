import { Grid, IconButton } from '@mui/material';
import React from 'react'
import './CommonFormatGridStructure.css'
import EastIcon from '@mui/icons-material/East';

const CommonFormatGridStructure = (props) => {
    const { content, flagNoBorder } = props;

    return (
        <Grid container gap={3} justifyContent={"center"} mt={5}>
            {content.map((data) =>
                <Grid item className={`${flagNoBorder ? 'common-grid-format-style-without-border' : 'common-grid-format-style'}`}>
                    <Grid container direction={"column"} alignItems={`${flagNoBorder ? 'start' : 'center'}`} gap={1}>
                        <Grid item>
                            <img src={data.gridIcon} alt="" height={"24"} width={"24"} />
                        </Grid>
                        <Grid item>
                            <span className={`${flagNoBorder ? 'flagNoBorder-common-grid-heading-text-style' : 'common-grid-heading-text-style'}`}>{data.gridHeading}</span>
                        </Grid>
                        <Grid item mt={1}>
                            <span className={`${flagNoBorder ? 'flagNoBorder-common-grid-subheading-text-style' : 'common-grid-subheading-text-style'}`}>{data.gridContent}</span>
                        </Grid>
                        {flagNoBorder &&
                            <Grid item>
                                <Grid container gap={1} justifyContent={"center"}>
                                    <Grid item>
                                        <a href="/your-link" className="learn-more-link" style={{ color: '#7A7A7A;' }}>
                                            Learn more
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default CommonFormatGridStructure
