import React from 'react'
import './InstagramImproveTeamCollabration.css'
import SideBySideSectionWithBtn from '../GlobalComponents/SideBySideSectionWithBtn/SideBySideSectionWithBtn'
import img1 from '../../assests/Solution/Instagram/instagram 5.png'
import EastIcon from '@mui/icons-material/East';

const InstagramImproveTeamCollabration = () => {

    const heroSectionData = {
        heading: "Easily manage Instagram conversations from the Chronox multichannel tool",
        description: "Recommend products directly in the Instagram conversation with done-for-you sales Flows.",
        image: img1,
        btnText: "Try the Chronox multichannel tool",
        pointText: [
            'Connect your account to the Chronox multichannel and easily switch between Messenger, Instagram, WhatsApp, email, and live chat messages.',
            'Transfer complex issues to live agents, assign conversations, and work on high-priority cases with your teammates, 100% in sync.',
            'Transfer your sales info to Zapier and manage your whole sales funnel with a helicopter view.',
        ],
        flagImageDirection: "right",
        btnGlobalCustomStyle: 'in-gcs-style',
        btnVariant: 'text',
        globalEndIcon: <EastIcon />

    }

    return (
        <>
            <SideBySideSectionWithBtn content={heroSectionData} />
        </>
    )
}

export default InstagramImproveTeamCollabration