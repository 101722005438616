import { Box, Button, Dialog, Grid, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './ChatbotNewFrontSection.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import chatbotWhatsappsubchat from '../../assests/ChatbotWhatsapp/cw-carousel-subImage1.svg'
import chatbotWhatsappchatBg1 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage1.svg'

import chatbotWhatsappsubchat2 from '../../assests/ChatbotWhatsapp/cw-carousel-subimage2.svg'
import chatbotWhatsappchatBg2 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage2.svg'

import chatbotWhatsappsubchat3 from '../../assests/ChatbotWhatsapp/cw-carousel-subimage3.svg'
import chatbotWhatsappchatBg3 from '../../assests/ChatbotWhatsapp/cw-carousel-bgimage3.svg'

import { Carousel } from 'react-responsive-carousel';
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Slider from "react-slick";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import metaProvider from './../../assests/LandingPage/metaProvider.svg';
import SplitText from "gsap/SplitText";
gsap.registerPlugin(SplitText)
gsap.registerEffect({
    name: "fade",
    effect: (targets, config) => {
        return gsap.fromTo(targets,
            { duration: config.duration, opacity: 0, scale: 0.3, },
            { duration: config.duration, opacity: 1, scale: 1 }
        );
    },
    defaults: { duration: 1 },
    extendTimeline: true,
});

const ChatbotNewFrontSection = () => {
    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const smallScreen = useMediaQuery('(max-width : 900px) and (min-width : 0px)')

    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);

    // const headingBoxRef = useRef(null)
    // const introImgRef = useRef(null)

    // useLayoutEffect(() => {
    //     const timeLine = gsap.timeline()
    //     timeLine.fade(introImgRef.current)

    //     const splitText = Array.from(headingBoxRef.current.children)
    //     splitText.forEach((element, ind) => {
    //         const textChars = new SplitText(element, { type: "chars, words" });
    //         gsap.fromTo(
    //             textChars.chars,
    //             { opacity: 0, y: 20 },
    //             {
    //                 opacity: 1,
    //                 y: 0,
    //                 duration: 1,
    //                 delay: ind * 0.5,
    //                 ease: 'power3.out',
    //                 stagger: 0.05,
    //             }
    //         );
    //     })
    //     return () => {
    //         timeLine.kill()
    //         splitText.forEach((element) => {
    //             const textChars = new SplitText(element, { type: "chars, words" });
    //             textChars.revert();
    //         });
    //     };
    // }, [])

    const carouselData = [
        {
            subImage: chatbotWhatsappsubchat3,
            subImageBg: chatbotWhatsappchatBg3,
            headingData: "Digital Sales",
            subData: "Drive more orders and reservations directly from messages with AI Agents designed to sell. Whether it’s promoting special offers, taking food orders, or securing reservations, our solution engages customers instantly and converts chats into revenue. No missed opportunities—just streamlined digital sales that keep your restaurant thriving.",
            buttonData: "Explore Now",
            alterText: "Chatting with property's whatapp chatbot"
        },
        {
            subImage: chatbotWhatsappsubchat2,
            subImageBg: chatbotWhatsappchatBg2,
            headingData: "Lead Generation",
            subData: "Engage potential tenants and capture high-quality leads without lifting a finger. Our AI Agents handle inquiries, schedule viewings, and collect essential details—seamlessly turning conversations into actionable leads. Streamline your leasing process and fill vacancies faster with intelligent, real-time engagement..",
            buttonData: "Explore Now",
            alterText: "Chatting with property's whatapp chatbot"
        },
        {
            subImage: chatbotWhatsappsubchat,
            subImageBg: chatbotWhatsappchatBg1,
            headingData: "Customer Support",
            subData: "Provide instant support for your customers’ needs. Our AI Agents handle product inquiries, troubleshoot issues, and even process returns—all in real-time. No more wait times or overloaded support teams—just fast, reliable assistance whenever your customers need it.",
            buttonData: "Explore Now",
            alterText: "Chatting with watch's whatapp chatbot"
        },
    ]

    return (
        <>

            {flagOpenBookaMeetingDemo && (
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            )}
            <Grid container className='front-main-section-container'>
                <Grid item className='cicrle-component'></Grid>
                <Grid item xl={8.5} lg={11} md={11} sm={11} xs={11} className='front-head-text-main-item'>
                    <Grid container>
                        <Grid item xl={11} lg={11} md={11} sm={11} xs={12} className='front-head-text-item'>
                            <Typography
                                variant='span'
                                className='front-head-normal-text-color'
                            // sx={{ fontWeight: '500', fontFamily: 'sans !important' }}
                            >
                                Instantly build Gen-AI Agents <br></br>
                            </Typography>
                            <Typography
                                variant='span'
                                className='front-head-normal-sub-text-color'
                            >
                                Engage, Automate, and Grow Across All Channels
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={9} lg={9} md={9} className='front-head-text-item'>
                            <Typography variant='span' className='front-head-sub-normal-text-color'>
                                {/* Discover how our AI-driven chatbot transforms conversations into conversions, enhancing user experience while optimizing  your business operations. */}
                                Create powerful AI agents that seamlessly integrate with your website, WhatsApp, Facebook, Instagram and more. Instantly handle customer inquiries, process orders, manage returns, and automate key business actions, freeing your team to drive growth and innovation.
                            </Typography>
                        </Grid>

                        {/* <Grid item xs={12} className='front-head-btn-item'>
                            <Grid container sx={{ justifyContent: 'center', gap: '20px' }}>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='front-head-free-btn'
                                        // endIcon={<ArrowForwardIcon style={{ color: "white" }} />}
                                        // onClick={() => window.open(`https://scheduler.zoom.us/krishna-writewiz/one-on-one`, '_blank')}
                                        onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')}
                                    >
                                        Build your own Gen-AI Chatbot
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button
                                        variant='outlined'
                                        fullWidth
                                        size='medium'
                                        className='front-head-demo-btn'
                                        onClick={() => {
                                            setFlagOpenBookaMeetingDemo(true)
                                        }}
                                    >
                                        Book a Demo
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} className='front-head-btn-item'>
                            <Grid container sx={{ justifyContent: 'center', gap: '20px' }} direction={"row"} flexWrap={{ xs: "wrap", sm: "nowrap" }}>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='front-head-free-btn'
                                        onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_self')}
                                    >
                                        Build your own AI Agent
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        fullWidth
                                        size='large'
                                        className='front-head-free-new-btn'
                                        onClick={
                                            // () => window.open("https://scheduler.zoom.us/krishna-writewiz/one-on-one", "_blank")
                                            () => {
                                                setFlagOpenBookaMeetingDemo(true)
                                            }
                                        }
                                    >
                                        Book a Demo
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className='front-head-btn-item'>
                            <Grid container sx={{ justifyContent: 'center', gap: { xs: '20px', sm: '80px', md: '110px' } }} direction={{ xs: "column", sm: "row" }} flexWrap={"nowrap"} alignItems={"center"}>
                                <Grid item>
                                    <Grid container direction={"row"} flexWrap={"nowrap"} alignItems={"center"} gap={1}>
                                        <Grid item>
                                            <span className='hash-class-styles'>#1</span>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction={"column"} alignItems={"start"}>
                                                <Grid item>
                                                    <span className='verified-text-style'>Verified</span>
                                                </Grid>
                                                <Grid item>
                                                    <span className='provider-text-style'>Provider</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <img src={metaProvider} className='meta-provider-image-style' />
                                </Grid>
                            </Grid>
                        </Grid>

                        

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ margin: '40px auto 20px', position: "relative" }}>
                            <Slider
                                dots={false}
                                infinite={true}
                                autoplay={true}
                                className='chatbot-click-front-slider'
                                speed={800}
                                arrow={false}
                                fade
                                slidesToShow={1}
                                slidesToScroll={1}
                                nextArrow={<EastIcon />}
                                prevArrow={<WestIcon />}
                                // appendDots={dots => (
                                //     <div className="dot-container">
                                //         <ul className="chatbot-dots">{dots}</ul>
                                //     </div>
                                // )}
                            >
                                {carouselData.map((data, index) => {
                                    return (
                                        // <div key={index} className="slide" style={{ position: "relative", textAlign: "center", color: "white" }}>
                                        //     {/* <img src={data.subImageBg} alt="Landscape" style={{width: "100%", height: "100%"}}/> */}
                                        //     <Grid item sx={{backgroundImage: "../../assests/ChatbotWhatsapp/cw-carousel-bgimage3.svg", height: "500px"}}>
                                        //         <Grid container direction={"column"}>
                                        //             <Grid item>
                                        //                 <span>{data.headingData}</span>
                                        //             </Grid>
                                        //             <Grid item>
                                        //                 <span></span>
                                        //             </Grid>
                                        //             <Grid item>
                                        //                 <span></span>
                                        //             </Grid>
                                        //         </Grid>
                                        //     </Grid>
                                        // </div>
                                        <div className='slide'>
                                            <Grid item className='carousel-content-component' sx={{':before': {backgroundImage: `url(${data.subImageBg})`}}}>
                                                <Grid container direction={"row"} flexWrap={"nowrap"} justifyContent={"space-between"} alignItems={"center"} sx={{zIndex: "1"}}>
                                                    <Grid item xs={12} md={7}>
                                                        <Grid container direction={"column"} gap={3}>
                                                            <Grid item>
                                                                <span className='carousel-heading-style'>{data.headingData}</span>
                                                            </Grid>
                                                            <Grid item>
                                                                <span className='carousel-subheading-style'>{data.subData}</span>
                                                            </Grid>
                                                            {smallScreen &&
                                                                <Grid item className='carousel-image-position-style'>
                                                                    <img src={data.subImage} className='carousel-image-component' />
                                                                </Grid>
                                                            }
                                                            <Grid item mt={{xs:1, md:3}} textAlign={{xs: "center", md: "left"}}>
                                                                <Button className='carousel-button-style'>{data.buttonData}</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item sx={{position: "absolute", top: "-90px", right: 0, transform: "translate(20px)"}}> */}
                                                </Grid>                                       
                                            </Grid>
                                            {!smallScreen &&
                                                <Grid item className='carousel-image-position-style'>
                                                    <img src={data.subImage} />
                                                </Grid>
                                            }
                                        </div>
                                    )
                                })}

                            </Slider>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}


export default ChatbotNewFrontSection