import React from 'react';
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import './CarouselAnimation.css'

const MobileCarouselAnimation = () => {
    const smallScreen = useMediaQuery('(min-width: 0px) and (max-width: 1000px)')

    // const mainData = [
    //     {
    //         name: "one",
    //         text: "Engage with your Ideas Instantly!",
    //         smallTitle: "Chrome Extension",
    //         title: "Turn every tab into a canvas with your AI-powered companion.",
    //         image: "https://cdn.pixabay.com/photo/2015/05/04/20/03/purple-wallpaper-752886_1280.jpg",
    //         description: `Ever had an idea while browsing the web? With our Chrome Extension, instantly access WriteWiz’s powerful features. Create, edit, and save content without switching tabs. The web is your canvas; let’s make every word count,`,
    //     },
    //     {
    //         name: "two",
    //         text: "Make it more than content—make it you.",
    //         smallTitle: "Brand/Individual Persona",
    //         image: "https://cdn.pixabay.com/photo/2015/05/04/20/03/purple-wallpaper-752886_1280.jpg",
    //         title: "Your voice, our craft—persona perfected",
    //         description:
    //             "Craft content that echoes your unique voice or your brand’s personality. Whether you're an enthusiastic startup or a seasoned blogger, define your persona and let WriteWiz curate content that resonates with your audience. Stand out. Be authentic. Be you",
    //     },
    //     {
    //         name: "three",
    //         text: "Power Your Marketing with Precision",
    //         image: "https://cdn.pixabay.com/photo/2015/05/04/20/03/purple-wallpaper-752886_1280.jpg",
    //         smallTitle: "Campaigns",
    //         title: "Every asset, every platform—your campaign content, simplified",
    //         description:
    //             "Embarking on a new product launch? Promoting a cause? With WriteWiz, structure your campaigns with ease. Generate series of related content, keeping a consistent voice throughout, and ensure every piece aligns perfectly with your campaign’s objective.",
    //     },
    //     {
    //         name: "four",
    //         image: "https://cdn.pixabay.com/photo/2015/05/04/20/03/purple-wallpaper-752886_1280.jpg",
    //         text: "Transform Ideas into Impactful Words",
    //         smallTitle: "Generate Content or Summarize",
    //         title: "Less fluff, more stuff.",
    //         description:
    //             "Whether you need detailed blog posts or concise summaries, WriteWiz has your back. Turn your thoughts into well-structured content or distill lengthy articles into bite-sized insights. Embrace clarity and maximize engagement.",
    //     },
    //     {
    //         name: "six",
    //         text: "Enhance User Experience with AI-powered Dialogues",
    //         smallTitle: "Chatbots",
    //         image: "https://cdn.pixabay.com/photo/2015/05/04/20/03/purple-wallpaper-752886_1280.jpg",
    //         title: "Chat today, convert tomorrow",
    //         description:
    //             "Elevate your chatbot's conversational prowess with WriteWiz. Integrate seamlessly and craft responses that feel human. With our AI, ensure every chat leads to user satisfaction and brand loyalty.",
    //     },
    // ];


    const mainData = [
        {
            name: "one",
            text: "Stay connected with your customers where they chat the most",
            smallTitle: "WhatsApp",
            title: "Fast and Secure Communication on WhatsApp",
            description: `Deliver instant notifications by providing seamless updates on order status, confirmations, and alerts. With an impressive 90% open rate, your messages are guaranteed to be read promptly. Enhance engagement through rich media support, allowing you to share images, videos, voice messages, and more. Stay connected, keep customers informed, and offer support—all directly through WhatsApp.`,
        },
        {
            name: "two",
            text: "Turn conversations into conversions by interacting with your customers on Instagram.",
            smallTitle: "Instagram",
            title: "Engage and Sell on Instagram Direct Messages",
            description:
                "Provide real-time support by offering instant responses and valuable product insights. Ensure a seamless shopping experience with product recommendations and in-app checkout options through Instagram’s messaging. Increase engagement with DM-based contests, polls, and automated replies to build stronger customer relationships. Connect with customers where they shop—right on Instagram.",
        },
        {
            name: "three",
            text: "Power Your Marketing with Precision",
            smallTitle: "Facebook Messenger",
            title: "Stay ahead of the competition by offering proactive, real-time support on Facebook",
            description: "Deliver 24/7 automated support by providing instant answers to FAQs and common inquiries. Boost conversions by showcasing products and guiding customers through purchases directly within the chat. Create personalized conversations that foster lasting customer relationships—all on Facebook.",
        },
        {
            name: "four",
            text: "Your website is the front door to your business",
            smallTitle: "Website",
            title: "Create an Impactful First Impression on Your Website",
            description:
                "Provide instant chat support to greet visitors and quickly address common questions. Seamlessly escalate conversations to live agents when needed, ensuring smooth transitions. Display integrated FAQs to resolve issues on the spot and enhance user experience. Make a lasting first impression and build trust—starting from your homepage.",
        },

    ];

    return (
        <div className="usecasemobile-main-div" style={{ background: '#F7E9FF' }}>
            <Carousel
                autoPlay={true}
                className='CarouselStyles'
                infiniteLoop={true}
                autoFocus={true}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
                showStatus={false}

            >
                {mainData.map((data, index) => {
                    return (
                        <div className="usecasemobile-inner-div" key={index}>
                            <div className="usecasemobile-section">
                                <div
                                    className="usecasemobile-main-text"
                                >
                                    <div
                                        className="usecasemobile-main-text-inner-div"
                                    >
                                        <div className="usecasemobileSmallTitle" >
                                            {data.smallTitle}
                                        </div>

                                        <div className="carousel-main-title-text">{data.title}</div>

                                        {smallScreen ? (<></>) :
                                            (<>
                                                <div id="usecasemobileTitleDiv">
                                                    <span className="usecasemobileTitle">
                                                        {mainData[0].title?.split(" ").slice(0, -1).join(" ")}
                                                    </span>
                                                    <span className="usecasemobileTitle">
                                                        {mainData[0].title?.split(" ").splice(-1)[0]}
                                                    </span>
                                                </div>
                                            </>)
                                        }

                                        <div id="usecasemobileText">{data.text}</div>


                                        <div className="usecasemobileDescription">{data.description}</div>



                                    </div>
                                </div>
                                <div className="usecasemobile-main-image">
                                    <div className="usecasemobile-main-image-inner-div">
                                        <img
                                            src={data.image}
                                            style={{ width: "100%", }}
                                            alt=""
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                })}

            </Carousel>
        </div>
    )
}

export default MobileCarouselAnimation