import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import './SideBySideSectionWithBtn.css'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const SideBySideSectionWithBtn = (props) => {
    const {
        content,
        flagHeroSection,
    } = props

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={`${flagHeroSection ? "side-by-side-main-item-section" : "side-by-side-item-section"}`}>
                    <Grid container alignItems={"center"} height={"100%"}>
                        <Grid item className={`${flagHeroSection ? "side-by-side-main-hero-section" : "side-by-side-main-section"}`}>
                            <Grid container sx={{ height: '100%', flexWrap: { xs: "wrap", md: "nowrap" }, gap: { xs: '25px', sm: "70px" }, alignItems: 'center' }} >
                                {content.flagImageDirection == "left" &&
                                    <Grid item xs={12} className={'side-by-side-left-image-style'}>
                                        <img src={content.image} className='side-by-side-image-style' />
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <Grid container gap={{ xs: 3, md: 3 }} justifyContent={`${content.textDirection ? content.textDirection : "left"}`}>
                                        {content.flagLogoImageSrc &&
                                            <Grid item>
                                                <img width={"40px"} src={content.flagLogoImageSrc} alt={'image'}></img>
                                            </Grid>
                                        }
                                        {content.headingLabel &&
                                            <Grid item xs={12} textAlign={`${content.textDirection ? content.textDirection : "left"}`}>
                                                <span className={`${content.headingLabelCustomStyle ? content.headingLabelCustomStyle : "side-by-side-heading-text-style"}`}>{content.headingLabel}</span>
                                            </Grid>
                                        }
                                        <Grid item xs={12} textAlign={`${content.textDirection ? content.textDirection : "left"}`}>
                                            <span className={`${content.headingGlobalCustomStyle ? content.headingGlobalCustomStyle : "side-by-side-heading-text-style"}`}>{content.heading} </span>
                                        </Grid>
                                        <Grid item xs={12} textAlign={`${content.textDirection ? content.textDirection : "left"}`}>
                                            <span className={`${content.descriptionGlobalCustomStyle ? content.descriptionGlobalCustomStyle : "side-by-side-description-text-style"}`}>{content.description}</span>
                                        </Grid>
                                        {content?.pointText?.length > 0 && content?.pointText &&
                                            <Grid item xs={12}>
                                                <Grid container alignItems={`${content.textDirection ? content.textDirection : "flex-start"}`}>
                                                    {content?.pointText?.map((ele) => {
                                                        return (
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Grid container sx={{ justifyContent: 'space-between', alignItems: 'start' }}>
                                                                        <Grid item className='sbs-point-icons'>
                                                                            <FiberManualRecordIcon style={{ width: '100%', height: '100%', margin: '5px 0px 0px' }} />
                                                                        </Grid>
                                                                        <Grid item xs={12} className='sbs-point-text-item'>
                                                                            <Typography variant='span' className='sbs-point-text'>
                                                                                {ele}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>
                                        }
                                        {content.btnText &&
                                            <Grid item xs={12} textAlign={`${content.textDirection ? content.textDirection : "left"}`}>
                                                <Button
                                                    variant={`${content?.btnVariant ? content?.btnVariant : 'text'}`}
                                                    endIcon={content?.globalEndIcon || null}
                                                    className={`sbs-global-btn-style ${content?.btnGlobalCustomStyle ? content?.btnGlobalCustomStyle : ''}`}
                                                    onClick={content?.onClick}
                                                >
                                                    {content.btnText}
                                                </Button>
                                            </Grid>
                                        }
                                        {content.creditlabel &&
                                            <Grid item xs={12}>
                                                <Typography variant='span' className='sbs-credit-span'>
                                                    No Credit Card required
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>

                                {content.flagImageDirection == "right" &&
                                    <Grid item xs={12} className={'side-by-side-left-image-style'}>
                                        <img src={content.image} className='side-by-side-image-style' />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SideBySideSectionWithBtn
