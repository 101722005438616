import React from 'react';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
import appsGroup from "../../assests/Chatbot-Hero.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import chatbotVerified from "../../assests/chatbot-verified.png"
import chatbotMetaIcon from "../../assests/chatbot-meta.png"
import chatbotHero from "../../assests/chatbot-new-hero.png"
import EastIcon from '@mui/icons-material/East';
import "./ChatbotHero.css"
import LazyLoading from '../../Utils/GlobalDialog/LazyLoading/LazyLoading';

const ChatbotHero = () => {
    const smallScreen = useMediaQuery('(min-width: 0px) and (max-width: 1200px)')

    return (
        <>
            <LazyLoading flagShow={false} />
            <Grid container className='chb-main-container'  >
                <Grid item xs={11} className='chb-main-container-item' >
                    <Grid container className='chb-hero-container'>
                        {
                            !smallScreen &&
                            <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5}>
                                <Grid container className='chb-hero-img-container chb-image-animation-effect'>
                                    <img src={"https://storage.writewiz-staging.in/general/Chatbot_landing_page_image_1710940995444.png"} alt="gif" className='chb-hero-img' loading='eager' />
                                    {/* <LazyLoading src={"https://storage.writewiz-staging.in/general/Chatbot_landing_page_image_1710940995444.png"} className='chb-hero-img' flagShow={true} /> */}
                                </Grid>
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={12} lg={5.7} xl={5.7} className='chb-hero-content-items' >
                            <Grid container>
                                <Grid xs={12}>
                                    <Grid container className='chb-sub-heading-container chb-slideLeft-effect'>
                                        <Grid item >
                                            <Grid container className='chb-sub-heading-container'>
                                                <Typography variant='span' className='chb-hero-sub-heading'>
                                                    Verified
                                                </Typography>
                                                <img src={chatbotMetaIcon} className='chb-hero-meta-img' />
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container className='chb-sub-heading-container'>
                                                <Typography variant='span' className='chb-hero-sub-heading'>
                                                    Meta Tech Provider
                                                </Typography>
                                                <img src={chatbotVerified} className='chb-hero-verified-img' />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid xl={11} lg={12} md={11} sm={11} xs={11} className='chb-hero-main-heading-item chb-slideRight-effect'>
                                    <Grid container>
                                        <Typography className='chb-hero-main-heading'>
                                            Boost Customer Engagement with Next-Gen AI Chatbot
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xl={10} lg={11} md={11} sm={11} xs={12} className='chb-hero-content-items chb-slideLeft-effect'>
                                    <Grid container>
                                        <Typography className='chb-hero-content'>
                                            Discover how our AI-driven chatbot transforms conversations into conversions, enhancing user experience while optimizing your business operations.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} className='chb-hero-button-items chb-slideRight-effect'>
                                    <Grid container>
                                        <Button
                                            variant='contained'
                                            endIcon={<EastIcon style={{ fontWeight: '600', fontSize: '25px' }} />}
                                            className='chb-hero-button'
                                            onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')}
                                        >
                                            Build your own Gen-AI Chatbot
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className='chb-no-credit-item chb-slideLeft-effect' sx={{ marginTop: "12px " }}>
                                    <Typography variant="span" className='chb-no-credit-typo' >
                                        No credit card required
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                            smallScreen &&
                            <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5} className='chb-lower-frame-item chb-image-animation-effect'>
                                <Grid container className='chb-hero-img-container'>
                                    <img src={"https://storage.writewiz-staging.in/general/Chatbot_landing_page_image_1710940995444.png"} alt="gif" className='chb-hero-img' loading='lazy' />
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                </Grid>

            </Grid>
        </>
    )
}

export default ChatbotHero