import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { bottom } from "@popperjs/core";
import $ from "jquery";
import SmallTextStarIcon from '../../assests/star-extenstion.png'
import { Button } from "@mui/material";
import ForwardArrowIcon from '../../assests/forward-arrow.png'
import './CarouselAnimation.css'


import img1 from "../../assests/Platform/Omini/whatsapp.png";
import img2 from "../../assests/Platform/Omini/instagram.png";
import img3 from "../../assests/Platform/Omini/messager.png";
import img4 from "../../assests/Platform/Omini/website.png";


gsap.registerPlugin(ScrollTrigger);

const CarouselAnimaion = () => {
    const mainData = [
        {
            name: "one",
            text: "Stay connected with your customers where they chat the most",
            smallTitle: "WhatsApp",
            title: "Fast and Secure Communication on WhatsApp",
            description: `Deliver instant notifications by providing seamless updates on order status, confirmations, and alerts. With an impressive 90% open rate, your messages are guaranteed to be read promptly. Enhance engagement through rich media support, allowing you to share images, videos, voice messages, and more. Stay connected, keep customers informed, and offer support—all directly through WhatsApp.`,
        },
        {
            name: "two",
            text: "Turn conversations into conversions by interacting with your customers on Instagram.",
            smallTitle: "Instagram",
            title: "Engage and Sell on Instagram Direct Messages",
            description:
                "Provide real-time support by offering instant responses and valuable product insights. Ensure a seamless shopping experience with product recommendations and in-app checkout options through Instagram’s messaging. Increase engagement with DM-based contests, polls, and automated replies to build stronger customer relationships. Connect with customers where they shop—right on Instagram.",
        },
        {
            name: "three",
            text: "Power Your Marketing with Precision",
            smallTitle: "Facebook Messenger",
            title: "Stay ahead of the competition by offering proactive, real-time support on Facebook",
            description: "Deliver 24/7 automated support by providing instant answers to FAQs and common inquiries. Boost conversions by showcasing products and guiding customers through purchases directly within the chat. Create personalized conversations that foster lasting customer relationships—all on Facebook.",
        },
        {
            name: "four",
            text: "Your website is the front door to your business",
            smallTitle: "Website",
            title: "Create an Impactful First Impression on Your Website",
            description:
                "Provide instant chat support to greet visitors and quickly address common questions. Seamlessly escalate conversations to live agents when needed, ensuring smooth transitions. Display integrated FAQs to resolve issues on the spot and enhance user experience. Make a lasting first impression and build trust—starting from your homepage.",
        },

    ];

    // const ChangeNextElement = (e) => {
    //     const idOfCurrentImageSection = e.trigger.getAttribute("id");
    //     const idOfCurrentTextSection = `${idOfCurrentImageSection}-text`;

    //     const tempNextComponentText = mainData.filter(
    //         (el) => el.name === idOfCurrentImageSection
    //     );

    //     // if(idOfCurrentImageSection != 'one'){

    //     const nextComponentTitle = tempNextComponentText[0]?.title
    //         ?.split(" ")
    //         .slice(0, -1)
    //         .join(" ");
    //     const nextComponentText = tempNextComponentText[0]?.text;
    //     const nextComponentTitleLastWord = tempNextComponentText[0]?.title
    //         ?.split(" ")
    //         .splice(-1)[0];
    //     const nextComponentDescription = tempNextComponentText[0]?.description;
    //     const nextComponentSmallTitle = tempNextComponentText[0]?.smallTitle;

    //     const newScrollSmallTitleDiv = document.createElement("div");
    //     const newScrollSmallTitleIcon = document.createElement("img");
    //     newScrollSmallTitleIcon.src = SmallTextStarIcon;
    //     newScrollSmallTitleIcon.classList.add("small-title-icon");
    //     const newScrollSmallTitleText = document.createTextNode(`${nextComponentSmallTitle}`);
    //     newScrollSmallTitleDiv.append(newScrollSmallTitleIcon);
    //     newScrollSmallTitleDiv.append(newScrollSmallTitleText);

    //     $("#newScrollSmallTitle").html(newScrollSmallTitleDiv);
    //     // $("#newScrollSmallTitle").text(`${nextComponentSmallTitle}`);
    //     $("#newScrollTitle").text(`${nextComponentTitle}`);
    //     $("#newScrollText").text(`${nextComponentText}`);
    //     $("#newScrollTitleLastWord").text(`${nextComponentTitleLastWord}`);
    //     $("#newScrollDescription").text(`${nextComponentDescription}`);

    //     $("#newScrollTitle").addClass("animateTextFadeIn");
    //     $("#newScrollTitleDiv").addClass("animateTextFadeIn");
    //     $("#newScrollText").addClass("animateTextFadeIn");
    //     $("#newScrollTitleLastWord").addClass("animateTextFadeIn");
    //     $("#newScrollDescription").addClass("animateTextFadeIn");
    //     $("#newScrollSmallTitle").addClass("animateTextFadeIn");
    //     $("newScrollButton").addClass("animateTextFadeIn");

    //     setTimeout(() => {
    //         $("#newScrollSmallTitle").removeClass("animateTextFadeIn");
    //         $("#newScrollTitle").removeClass("animateTextFadeIn");
    //         $("#newScrollTitleDiv").removeClass("animateTextFadeIn");
    //         $("#newScrollText").removeClass("animateTextFadeIn");
    //         $("#newScrollTitleLastWord").removeClass("animateTextFadeIn");
    //         $("#newScrollDescription").removeClass("animateTextFadeIn");
    //         $("newScrollButton").removeClass("animateTextFadeIn");
    //     }, 1000);
    // };


    const ChangeNextElement = (e) => {
        const idOfCurrentImageSection = e.trigger?.getAttribute("id");
        const tempNextComponentText = mainData.find(
            (el) => el.name === idOfCurrentImageSection
        );

        if (!tempNextComponentText) {
            console.error(`No matching data found for id: ${idOfCurrentImageSection}`);
            return;
        }

        const nextComponentTitle = tempNextComponentText?.title?.split(" ").slice(0, -1).join(" ") || '';
        const nextComponentText = tempNextComponentText?.text || '';
        const nextComponentTitleLastWord = tempNextComponentText?.title?.split(" ").splice(-1)[0] || '';
        const nextComponentDescription = tempNextComponentText?.description || '';
        const nextComponentSmallTitle = tempNextComponentText?.smallTitle || '';

        const newScrollSmallTitleDiv = document.createElement("div");
        const newScrollSmallTitleIcon = document.createElement("img");
        newScrollSmallTitleIcon.src = SmallTextStarIcon;
        newScrollSmallTitleIcon.classList.add("small-title-icon");
        const newScrollSmallTitleText = document.createTextNode(nextComponentSmallTitle);
        newScrollSmallTitleDiv.append(newScrollSmallTitleIcon, newScrollSmallTitleText);

        $("#newScrollSmallTitle").html(newScrollSmallTitleDiv);
        $("#newScrollTitle").text(nextComponentTitle);
        $("#newScrollText").text(nextComponentText);
        $("#newScrollTitleLastWord").text(nextComponentTitleLastWord);
        $("#newScrollDescription").text(nextComponentDescription);

        // Apply animations
        const elements = [
            "#newScrollSmallTitle",
            "#newScrollTitle",
            "#newScrollTitleDiv",
            "#newScrollText",
            "#newScrollTitleLastWord",
            "#newScrollDescription",
            "newScrollButton",
        ];

        elements.forEach((selector) => $(selector).addClass("animateTextFadeIn"));

        setTimeout(() => {
            elements.forEach((selector) => $(selector).removeClass("animateTextFadeIn"));
        }, 1000);
    };


    useEffect(() => {
        const textElement = document.querySelector(
            "#newscrollelement-main-text-inner-div"
        );
        const mainDiv = document.querySelector(".necarouselscrollelement-main-div");
        const mainImage = document.querySelector(".newscrollelement-main-image-wrapper");
        const elements = document.querySelectorAll(".abcdefg");
        const lastSection = document.querySelector(
            "#necarouselscrollelement-main-div-inner-last"
        );
        gsap.timeline({
            scrollTrigger: {
                trigger: textElement,
                start: "center center", // Start pinning at the top of .necarouselscrollelement-main-div
                endTrigger: lastSection,
                end: "center center", // End pinning at the bottom of .newscrollelement-main-image-wrapper
                scrub: true,
                pin: "#newscrollelement-main-text-inner-div",
                anticipatePin: 1,
                // markers: true,
            },
        });
        elements.forEach((element) => {
            gsap.timeline({
                scrollTrigger: {
                    trigger: element,
                    start: "20% 70%",
                    end: "20% 10%",
                    scrub: true,
                    anticipatePin: 1,
                    onEnter: (e) => ChangeNextElement(e),
                    onEnterBack: (e) => ChangeNextElement(e),
                    // markers: true,
                },
            });
        });
    }, []);

    return (
        <div className="necarouselscrollelement-main-div">
            <div
                id="necarouselscrollelement-main-div-inner"
                className="necarouselscrollelement-main-div-inner"
            >
                <div className="newscrollelement-main-image-wrapper">
                    <div id="one" className="abcdefg">
                        <div className="newscrollelement-main-image-container">
                            <img
                                src={img1}
                                className="newscrollelement-main-image"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div
                    id="newscrollelement-main-text"
                    className="newscrollelement-main-text"
                >
                    <div className="tempDiv"></div>
                    <div
                        id="newscrollelement-main-text-inner-div"
                        className="newscrollelement-main-text-inner-div"
                    >
                        <div id="newScrollSmallTitle" className="text small-title">
                            {mainData[0].smallTitle}
                        </div>

                        <div id="newScrollTitleDiv">
                            <span id="newScrollTitle">
                                {mainData[0].title?.split(" ").slice(0, -1).join(" ")}
                            </span>
                            <span id="newScrollTitleLastWord">
                                {mainData[0].title?.split(" ").splice(-1)[0]}
                            </span>
                        </div>
                        <div id="newScrollText">{mainData[0].text}</div>
                        <div id="newScrollDescription">{mainData[0].description}</div>
                        {/* <div id="newScrollButton">
                            <Button
                                variant="outlined"
                                className="newscroll-learn-more-button"
                                size="medium"
                                onClick={() => window.open("/browser-extension", "_blank")}
                                sx={{
                                    border: 'none',
                                    borderRadius: '10px',
                                    padding: '6px 18px',
                                    boxShadow: '0 0 3px 0 #6A097D',
                                    color: '#6A097D',
                                    fontWeight: '600',
                                    textTransform: 'capitalize',
                                    fontSize: '18px',
                                    marginTop: '50px'
                                }}
                            >
                                Learn More
                                <img src={ForwardArrowIcon} className="newscroll-learn-more-button-forward-icon" />
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="necarouselscrollelement-main-div-inner">
                <div className="newscrollelement-main-image-wrapper">
                    <div id="two" className="abcdefg">
                        {" "}
                        <img
                            src={img2}
                            className="newscrollelement-main-image"
                            alt=""
                        />
                    </div>
                </div>
                <div className="newscrollelement-main-text">
                    <div className="newscrollelement-main-text-inner-div"></div>
                </div>
            </div>

            <div className="necarouselscrollelement-main-div-inner">
                <div className="newscrollelement-main-image-wrapper">
                    <div id="three" className="abcdefg">
                        {" "}
                        <img
                            src={img3}
                            className="newscrollelement-main-image"
                            alt=""
                        />
                    </div>
                </div>
                <div className="newscrollelement-main-text">
                    <div className="newscrollelement-main-text-inner-div"></div>
                </div>
            </div>

            <div className="necarouselscrollelement-main-div-inner">
                <div className="newscrollelement-main-image-wrapper">
                    <div id="four" className="abcdefg">
                        {" "}
                        <img
                            src={img4}
                            className="newscrollelement-main-image"
                            alt=""
                        />
                    </div>
                </div>
                <div className="newscrollelement-main-text">
                    <div className="newscrollelement-main-text-inner-div"></div>
                </div>
            </div>

            <div
                id="necarouselscrollelement-main-div-inner-last"
                className="necarouselscrollelement-main-div-inner"
            >
                <div className="newscrollelement-main-image-wrapper">
                    <div id="six" className="abcdefg">
                        {" "}
                        <img
                            src={img4}
                            className="newscrollelement-main-image"
                            alt=""
                        />
                    </div>
                </div>
                <div className="newscrollelement-main-text">
                    <div className="newscrollelement-main-text-inner-div"></div>
                </div>
            </div>
        </div>
    );
};

export default CarouselAnimaion;
