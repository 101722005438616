import { Grid } from '@mui/material'
import React from 'react'
import './FooterComponent.css'
import CommonFormatGridStructure from '../../CommonFormatGridStructure/CommonFormatGridStructure'
import gridIcon1 from './../../../assests/Linkedin.svg'

const FooterComponent = () => {
    const gridStructureData = [
        {
            gridID: 1,
            gridIcon: gridIcon1,
            gridHeading: "Protect patient information",
            gridContent: "Protect patient information with 2 Factor Authentication (2FA) to confirm patient identity"
        },
        {
            gridID: 2,
            gridIcon: gridIcon1,
            gridHeading: "30+ channels",
            gridContent: "Integrate 30+ messaging channels through Text, Voice, and Video to create a 'consult anywhere' experience"
        },
        {
            gridID: 3,
            gridIcon: gridIcon1,
            gridHeading: "Automated alerts",
            gridContent: "Send automated prescription updates to patients via WhatsApp, SMS. Remind them about overdue follow-up appointments"
        },
        {
            gridID: 4,
            gridIcon: gridIcon1,
            gridHeading: "Real-time 24*7 support",
            gridContent: "Provide quick resolutions to each patient's queries regardless of the time of the day and grographical location via AI-powered chatbots"
        },
        {
            gridID: 5,
            gridIcon: gridIcon1,
            gridHeading: "Enable remote diagnosis",
            gridContent: "Enable remote interactions that make it easy for patients to quickly get via images, video and audio clips"
        },
        {
            gridID: 6,
            gridIcon: gridIcon1,
            gridHeading: "Deploy in 4 weeks",
            gridContent: "OOTB integrations with leading CRM, ERP and scheduling platforms for faster go-to market"
        }
    ]

    return (
        <Grid item className='healthcare-footer-main-section'>
            <Grid container justifyContent={"center"}>
                <Grid item xl={9} lg={10.5} md={11} sm={11} xs={11}>
                    <Grid container justifyContent={"center"} gap={3}>
                        <Grid item xs={12} textAlign={"center"}>
                            <span className='healthcare-footer-text-color'>Why Choose Us?</span>
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            <CommonFormatGridStructure content={gridStructureData}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FooterComponent
