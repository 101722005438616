import { Grid } from '@mui/material'
import React, { useState } from 'react'
import './RetailSectorComponent.css'
import Navbar from '../../../Pages/Navbar/Navbar'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommonFormatImageContents from '../../CommonFormatImageContent/CommonFormatImageContents';
import demoImage1 from './../../../assests/LandingPage/commonSection1.svg'
import demoImage2 from './../../../assests/LandingPage/commonSection2.svg'
import demoImage3 from './../../../assests/LandingPage/commonSection3.svg'
import HeroSectionNew from '../../HeroSectionNew/HeroSectionNew';
import heroImage from './../../../assests/Industries/HealthCare/health-care-hero.svg'
import FooterComponent from '../HealthCareComponents/FooterComponent';
import ChannelCardsComponent from '../HealthCareComponents/ChannelCardsComponent';
import LandingFooter from '../../Footer/LandingFooter';
import ChatbotFAQs from '../../ChatbotFAQs/ChatbotFAQs';
import AiWhatsappScrollingSection from '../../AiWhatsappScrollingSection/AiWhatsappScrollingSection';

const RetailSectorComponent = (props) => {
    const { flagBanner, setFlagBanner } = props;

    const [chatbotFaqs, setChatbotFaqs] = useState([
        {
          question: "How does a WhatsApp chatbot work?",
          answer: "A WhatsApp Chatbot uses AI and predefined responses to interact with users, answer queries, and perform tasks, providing a seamless experience.",
        },
        {
          question: "What are the benefits of using WhatsApp chatbots?",
          answer: "WhatsApp Chatbots improve efficiency, offer 24/7 support, and personalize interactions, leading to enhanced customer satisfaction and business growth."
        },
        {
          question: "Are WhatsApp chatbots secure?",
          answer: "WhatsApp Chatbots prioritize security, ensuring data protection and compliance with WhatsApp's policies.",
        },
        {
          question: "What can I ask the chatbot?",
          answer: "You can ask questions related to our services, product details, pricing, account management, support queries, and more. Our chatbot is designed to provide you with quick information and assist you in navigating our website."
        },
        {
          question: "How does the chatbot understand my questions?",
          answer:
            "Our chatbot uses advanced AI algorithms to understand natural language, which means you can ask questions just as you would to a human."
        },
    ])

    const heroContentData = {
        contentID: 1,
        contentHeading1: "Focused AI Innovations in Retail:",
        contentHeading2: " Automated Solutions that Anticipate Customer Needs and Optimize Inventory",
        contentSubHeading: "Boost customer engagement and streamline inventory management with our AI chatbot. Whether it's helping customers find the right products or ensuring shelves are stocked, our AI solutions make your retail experience smarter and more efficient.",
        buttonData: [
            {
                buttonStyle: "dark",
                buttonText: "Get Started"
            },
            {
                buttonStyle: "light",
                buttonText: "Book a Demo"
            }
        ],
        listIcon: <CheckCircleOutlineIcon sx={{fontSize: "20px"}} />,
        flagListData: false,
        // listData: [
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi.",
        //     "Lorem ipsum dolor sit amet consectetur. Ornare varius risus erat mi."
        // ],
        flagFloatImage: false,
        contentImageSection: [
            {
                imgID: 1,
                imgName: "Image 1",
                imgLink: heroImage,
            },
        ]
    }

    const retailSectorData = [
        {
            healthCareID: 1,
            heading: "24/7 Customer Assistance",
            subHeading: "Support Whenever It's Needed",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Make sure your customers never have to wait. Our AI offers immediate responses, ensuring a satisfying shopping experience.",
            subData: [
                "Promptly address customer inquiries and issues.",
                "Automate updates related to inventory and order statuses.",
                "Easily transition to human representatives for complex questions." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1

            // healthCareID: 1,
            // heading: "Boost Brand Visibility & Drive Customer Engagement",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            // subData: [
            //     "Share updates on product launches, sales events, and exclusive promotions, ensuring your customers receive the information they need when they're ready to shop.",
            //     "Leverage popular channels like Facebook, Google Business Messages (GBM), Instagram, and WhatsApp to deliver tailored shopping experiences and recommendations.",
            //     "Help shoppers locate nearby retail outlets and services, making it easier for them to find what they need and visit your physical locations." 
            // ],
            // buttonData: "Explore How Conversational Marketing Can Help",
            // imgData: demoImage1
        },
        {
            healthCareID: 2,
            heading: "Hassle-Free Returns and Exchanges",
            subHeading: "Simplified Return Process",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Make returning items straightforward for your customers. Our AI facilitates an easy and efficient returns process.",
            subData: [
                "AI manages return and exchange requests quickly.",
                "Provide customers with real-time updates and confirmations.",
                "Allow customers to choose their return method." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1

            // healthCareID: 2,
            // heading: "Simplify Orders & Streamline Customer Inquiries",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px"}}/>,
            // subData: [
            //     "Empower your customers to browse products, place orders, and make inquiries anytime, anywhere, using a chatbot on your website or via WhatsApp.",
            //     "Let customers modify their orders or delivery details with ease, giving them the flexibility to make changes as needed.",
            //     "Send timely notifications on order status, delivery reminders, and post-purchase surveys to keep customers informed and engaged." 
            // ],
            // buttonData: "Discover More About Proactive Messaging",
            // imgData: demoImage2
        },
        {
            healthCareID: 3,
            heading: "Strengthened Customer Engagement",
            subHeading: "Foster Lasting Relationships",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Engage customers effectively through targeted outreach. Our AI handles follow-ups, promotions, and loyalty initiatives.",
            subData: [
                "Send personalized messages regarding offers and new products.",
                "Interact with customers on multiple channels (email, SMS, social media).",
                "Turn casual shoppers into loyal clients with consistent real-time engagement." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1

            // healthCareID: 3,
            // heading: "Deliver Instant Support & Improve Customer Satisfaction",
            // subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px"}}/>,
            // subData: [
            //     "Engage with customers on platforms they use daily—such as Facebook, GBM, Instagram, and WhatsApp—to provide quick and efficient support.",
            //     "Guide potential customers by offering the right product information, promotions, and delivery options at just the right time in their shopping journey.",
            //     "Make it easy for customers to discover your closest retail locations or pickup points, enabling seamless in-person shopping or delivery options." 
            // ],
            // buttonData: "Learn More About How We Improve Customer Care",
            // imgData: demoImage3
        },
        {
            healthCareID: 4,
            heading: "Boost Brand Recognition",
            subHeading: "Automate Marketing Outreach",
            subDataIcon: <CheckCircleOutlineIcon sx={{display: "flex", fontSize: "17px", verticalAlign: "middle"}}/>,
            classificationData: "Enhance your market presence effortlessly. Automate personalized marketing efforts to increase brand awareness.",
            subData: [
                "Send targeted messages for promotions and new products.",
                "Engage potential customers across diverse platforms (social media, email, SMS).",
                "Convert website visitors into sales through compelling content." 
            ],
            buttonData: "Explore Conversational Marketing",
            imgData: demoImage1
        }
    ] 

    return (
        <Grid container>
            <Navbar flagBanner={flagBanner} setFlagBanner={setFlagBanner} />

            <Grid item xs={12} mt={{ xs: flagBanner ? 10 : 0, md: flagBanner ? 8 : 0 }} sx={{ overflowX: "hidden" }}>
                <Grid container>
                    <Grid item xs={12} className='healthcare-new-hero-section'>
                        <HeroSectionNew content={heroContentData}/>
                    </Grid>

                    <Grid item xs={9} className='brsw-new-expertise-section-main-item'>
                        <AiWhatsappScrollingSection />
                    </Grid>

                    {
                        retailSectorData.map((data) => 
                            <Grid item xs={12} className='common-format-new-head-section-main-item'>
                                <CommonFormatImageContents content={data} />
                            </Grid>
                        )
                    }

                    <Grid item xs={12} className='common-format-new-card-section'>
                        <ChannelCardsComponent />
                    </Grid>

                    <Grid item xs={12} className='common-format-new-footer-section'>
                        <FooterComponent />
                    </Grid>

                    <Grid item xs={12} className='chatbot-faq-section'>
                        <ChatbotFAQs faqData={chatbotFaqs} />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <LandingFooter />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default RetailSectorComponent
