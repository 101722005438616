import React from 'react'
import './ChatbotWhatsappGridSection.css'
import '../BrowserExtFeatures/BrowserExtFeatures.css'
import { CardMedia, Divider, Grid, Typography, useMediaQuery } from '@mui/material'

import rewrite from "../../assests/CE-Rewrite.jpg"
import persona from "../../assests/CE-Persona.jpg"
import customPrompt from "../../assests/CE-Custom Prompt.jpg";
import compose from "../../assests/CE-Compose.jpg";
import mark from '../../assests/ChatbotWhatsapp/cw-right-marks.png'
import firstImage from '../../assests/ChatbotWhatsapp/cw-chat-first-card-1.svg'
import secondImage from '../../assests/ChatbotWhatsapp/cw-chat-second-card-1.svg'
import thirdImage from '../../assests/ChatbotWhatsapp/cw-chat-third-card-1.svg'
import messanger from '../../assests/messenger.svg'

const ChatbotWhatsappGridSection = (props) => {
    const { groupSectionData } = props
    // const isScreenWidthBelow900 = useMediaQuery('(max-width : 900px) and (min-width : 0px)')
    // const groupSection = [
    //     {
    //         mainHeading: "Instantly sync your Data Flow across your systems",
    //         subheading1: "Connect in real-time with APIs, CRM, and databases to ensure data is always current and accurate.",
    //         subheading2: "Enable smarter decisions with data-driven insights directly within your WhatsApp conversations.",
    //         subheading3: "Reduce response times and increase efficiency with automated data retrieval and updates.",
    //         leftSideimage: false,
    //         image: firstImage,
    //         altText: 'Connect with all plateforms and data-driven insights',
    //         points: [
    //             { label: 'Real-time connectivity', img: messanger },
    //             { label: 'Data-driven insights', img: messanger },
    //             { label: 'Smart WhatsApp decision', img: messanger },
    //         ]
    //     },
    //     {
    //         mainHeading: "Easy toggle between AI and Human Interaction",
    //         subheading1: "Toggle between AI-driven and manual conversations seamlessly to best suit the customer’s needs.",
    //         subheading2: "Use AI-assisted prompts to enhance human interactions, ensuring no detail is missed.",
    //         subheading3: "Maintain control with options to intervene manually when nuanced conversations are required.",
    //         leftSideimage: isScreenWidthBelow900 ? false : true,
    //         image: secondImage,
    //         altText: 'Easy interaction with AI and human entities',
    //         points: [
    //             { label: 'Seamless Toggles', img: messanger },
    //             { label: 'AI-assisted prompts', img: messanger },
    //             { label: 'Ensure detail accuracy', img: messanger },
    //         ]
    //     },
    //     {
    //         mainHeading: "Automate your Tasks & Messaging using API",
    //         subheading1: "Deploy automatic WhatsApp messages through our robust API, ensuring timely and consistent communication with users.",
    //         subheading2: "Customize message templates to reflect your brand’s tone and style, making automated interactions feel as personal as live chats.",
    //         subheading3: "Utilize automation to handle common inquiries and free up your team to focus on more complex customer needs, enhancing overall service efficiency.",
    //         leftSideimage: false,
    //         image: thirdImage,
    //         altText: 'Auto-generated message templates for your Tasks & Messaging',
    //         points: [
    //             { label: 'Automatic WhatsApp messages', img: messanger },
    //             { label: 'Brand-specific templates', img: messanger },
    //             { label: 'Personalized message templates', img: messanger },
    //         ]
    //     },

    // ]

    return (
        <>

            <Grid container>

                <Grid item xl={9} lg={10} md={11} sm={10} xs={11} className='brws-ext-feature-main-item'>
                    <Grid container>
                        {groupSectionData.map((data) => {
                            return (
                                <>
                                    <Grid item xs={12} className='brws-ext-feature-sub-item'>
                                        <Grid container className='cw-feature-sub-container'>

                                            {data.leftSideimage == false &&
                                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12} >
                                                    <Grid container className='brws-ext-feature-sub-right-section-container' justifyContent={"center"}>
                                                        <Grid item
                                                            className='brws-ext-feature-sub-right-section'
                                                            sx={{
                                                                marginBottom: '20px',
                                                            }}>
                                                            <CardMedia
                                                                component="img"
                                                                src={data.image}
                                                                alt={data.altText}
                                                                sx={{
                                                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                                                }}
                                                                className='wpc-grid-infp-img'
                                                            ></CardMedia>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }

                                            <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12} >
                                                <Grid container>
                                                    <Grid item xs={12} className='brws-ext-features-heading-item cw-point-head-text'>
                                                        <Typography component={'span'}
                                                            sx={{ fontSize: '33px', fontWeight: '600', color: '#333333' }}
                                                            className=''>
                                                            {data.mainHeading}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xl={9} lg={12} md={12} sm={12} xs={12} className=''>
                                                        <Grid container>

                                                            <Grid item xs={12} sx={{ margin: '0px 0px 25px' }}>
                                                                <Grid container sx={{ justifyContent: "space-between" }}>
                                                                    <Grid item xs={0.8}>
                                                                        <img src={mark} className="cw-mark-img" />
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        <Typography variant='span' className='cw-grid-dd-text'>
                                                                            {data.subheading1}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sx={{ margin: '0px 0px 25px' }}>
                                                                <Grid container sx={{ justifyContent: "space-between" }}>
                                                                    <Grid item xs={0.8}>
                                                                        <img src={mark} className="cw-mark-img" />
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        <Typography variant='span' className='cw-grid-dd-text'>
                                                                            {data.subheading2}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sx={{ margin: '0px 0px 25px' }}>
                                                                <Grid container sx={{ justifyContent: "space-between" }}>
                                                                    <Grid item xs={0.8}>
                                                                        <img src={mark} className="cw-mark-img" />
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        <Typography variant='span' className='cw-grid-dd-text'>
                                                                            {data.subheading2}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={12} sm={11} md={10}>
                                                        <Grid container className='cw-features-points-outter-container'>
                                                            {data.points.map((points) => (
                                                                <Grid item sx={{ maxWidth: { xs: "100px", sm: "150px" } }} >
                                                                    <Grid container sx={{ flexDirection: 'column', alignItems: 'center', }}>
                                                                        <Grid item ><img style={{ maxWidth: "45px" }} src={points.img} alt="" /></Grid>
                                                                        <Grid item sx={{ textAlign: "center", }}>
                                                                            <Typography variant='span' sx={{ fontSize: "15px", fontWeight: "600" }}>
                                                                                {points.label}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>

                                            {data.leftSideimage &&
                                                <Grid item xl={5.5} lg={5.5} md={5.7} sm={12} xs={12} >
                                                    <Grid container className='brws-ext-feature-sub-right-section-container' justifyContent={"center"}>
                                                        <Grid item className='brws-ext-feature-sub-right-section'>
                                                            <CardMedia
                                                                component="img"
                                                                src={data.image}
                                                                alt={data.altText}
                                                                sx={{
                                                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                                                }}
                                                                className='wpc-grid-infp-img'
                                                            ></CardMedia>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ChatbotWhatsappGridSection