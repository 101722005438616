import React from 'react'
import './ChannelCardsComponent.css'
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'
import Slider from 'react-slick';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ChannelCardsComponent = () => {
    const data = [
        {
          title: 'Website',
          description: 'Reach out to visitors proactively to boost website engagement.',
          icon: '🌐',
          link: '#',
        },
        {
          title: 'LiveChat',
          description: 'Support customers 24/7 to improve your customer satisfaction.',
          icon: '💬',
          link: '#',
        },
        {
          title: 'Messenger',
          description: 'Connect with Messenger users in real time to grow your audience.',
          icon: '📲',
          link: '#',
        },
        {
          title: 'Slack',
          description: 'Streamline office workflow to boost team productivity.',
          icon: '💼',
          link: '#',
        },
        {
            title: 'LiveChat',
            description: 'Support customers 24/7 to improve your customer satisfaction.',
            icon: '💬',
            link: '#',
        },
        {
            title: 'Slack',
            description: 'Streamline office workflow to boost team productivity.',
            icon: '💼',
            link: '#',
        },
        // Add more cards here in the future...
    ];      

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                },
            },
            {
                breakpoint: 1080,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                },
            },
        ],
    };

    return (
        <Grid item className='channel-cards-main-item-section'>
            <Grid container justifyContent={"center"}>
                <Grid item xl={9} lg={10.5} md={11} sm={11} xs={11}>
                    <Grid container justifyContent={"center"} gap={3}>
                        <Grid item xs={12} textAlign={"center"}>
                            <span className='healthcare-heading-text-color'>Caring that never stops</span>
                        </Grid>
                        <Grid item xs={12} sm={11.5} md={10.5} lg={9} x={8} textAlign={"center"}>
                            <span className='healthcare-sub-heading-text-color'>Add ChatBot to your website, LiveChat, and Facebook Messenger using our out-of-the-box integrations. Ensure continuous patient support across all your communication channels.</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', marginTop: 2 }}>
                                <Slider {...settings}>
                                    {data.map((item, index) => (
                                    <Grid item key={index} className='card-grid-style-component'>
                                        <Card className='card-component-style'>
                                            <CardContent>
                                                <Typography variant="h5" component="div">
                                                    {item.icon}
                                                </Typography>
                                                <Typography variant="span" className='card-heading-text-color' component="div" sx={{ marginY: 2 }}>
                                                    {item.title}
                                                </Typography>
                                                <Typography variant="span" component="div" className='card-subheading-text-color' sx={{ marginY: 2 }}>
                                                    {item.description}
                                                </Typography>
                                                <Button className='card-button-style' href={item.link} endIcon={<ChevronRightIcon />}>
                                                    More details
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    ))}
                                </Slider>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ChannelCardsComponent
